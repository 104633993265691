import { BLOG_POSTS_URL } from "../constants";
import { apiSlice } from "./apiSlice";

export const blogPostsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBlogPosts: builder.query({
      query: ({ keyword }) => ({
        url: BLOG_POSTS_URL,
        params: { keyword },
      }),
      providesTags: ["BlogPost"],
      keepUnusedDataFor: 5,
    }),
    getBlogPostFull: builder.query({
      query: (blogPostId) => ({
        url: `${BLOG_POSTS_URL}/${blogPostId}`,
      }),
      keepUnusedDataFor: 5,
    }),
    addBlogPost: builder.mutation({
      query: (newPost) => ({
        url: BLOG_POSTS_URL,
        method: "POST",
        body: newPost,
      }),
      invalidatesTags: ["BlogPost"],
    }),
    updateBlogPost: builder.mutation({
      query: (data) => ({
        url: `${BLOG_POSTS_URL}/${data.blogPostId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["BlogPost"],
    }),
    uploadBlogPostImage: builder.mutation({
      query: (data) => ({
        url: "/api/upload",
        method: "POST",
        body: data,
      }),
    }),
    deleteBlogPost: builder.mutation({
      query: (blogPostId) => ({
        url: `${BLOG_POSTS_URL}/${blogPostId}`,
        method: "DELETE",
      }),
    }),
    likeBlogPost: builder.mutation({
      query: (data) => ({
        url: `${BLOG_POSTS_URL}/${data.blogPostId}/like`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["BlogPost"],
    }),
  }),
});

export const {
  useGetBlogPostsQuery,
  useGetBlogPostFullQuery,
  useAddBlogPostMutation,
  useUpdateBlogPostMutation,
  useUploadBlogPostImageMutation,
  useDeleteBlogPostMutation,
  useLikeBlogPostMutation,
} = blogPostsApiSlice;
