// import { useInView } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import Alert from "react-bootstrap/Alert";
import { IoHeartCircle } from "react-icons/io5";
import AnimatedTitle from "./AnimatedTitle";
import "./Header.css";
import { useInView } from 'react-intersection-observer';

const Header = (props) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  // const ref = useRef(null);
  // const isInView = useInView(ref, { once: true });
  // const isInView = useInView(ref, { threshold: 0.01 }); // Adjust threshold as needed
  const [ref, isInView] = useInView({
    threshold: 0.1,
    triggerOnce: true, // if you want it to trigger once
  });

  useEffect(() => {
    // console.log('Element is in view:', isInView);
  }, [isInView]);
  
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1118);
    };

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures that the effect runs only once on mount
  const paragraphs = props.text.split("\n").map((paragraph, index) => (
    <p
      ref={ref}
      style={{
        transform: isInView ? "none" : "translateX(-200px)",
        opacity: isInView ? 1 : 0,
        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s",
      }}
      key={index}
      className="subtitle"
    >
      {paragraph}
    </p>
  ));
  return (
    <header className="page-header">
      <h1 className="title" color="dark">
        <AnimatedTitle title={props.title} />
      </h1>
      {isSmallScreen ? (
        <p
          ref={ref}
          style={{
            transform: isInView ? "none" : "translateX(-200px)",
            opacity: isInView ? 1 : 0,
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s",
          }}
          className="subtitle"
        >
          {props.text}
        </p>
      ) : (
        paragraphs
      )}
      {props.subtitle ? (
        <Alert key={props.title} variant="dark" className="alert-box">
          <IoHeartCircle className="alert-icon mx-1" />
          <span className="alert-text"> {props.subtitle}</span>
        </Alert>
      ) : (
        <></>
      )}
    </header>
  );
};

export default Header;
