import React from "react";
import { Button } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="error-page">
      <h2 className="error-header" data-text="404">
        404
      </h2>
      <p className="error-text" data-text="페이지가 존재하지 않습니다">
        페이지가 존재하지 않습니다
      </p>
      {/* <div className="search-container"> */}
        <Button onClick={() => navigate("/")} className="goBackBtn">
          <FaArrowLeft className="me-2" /> 메인으로 돌아가기
        </Button>
      {/* </div> */}
    </div>
  );
};

export default PageNotFound;
