import { motion } from "framer-motion";
import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

const LiquidButton = (props) => {
  const [isHover, setHover] = useState(false);
  const liquidFillVariants = {
    rest: { scaleY: 0, originY: 1 },
    hover: { scaleY: 1.5, originY: 1 },
  };
  return props.color === "bright" ? (
    <motion.button
      style={{
        width: "120px",
        height: "60px",
        padding: 0,
        backgroundColor: "#ff5000",
        border: "transparent",
        borderRadius: "110px",
        cursor: "pointer",
        overflow: "hidden",
        position: "relative",
      }}
      onHoverStart={() => setHover(true)}
      onHoverEnd={() => setHover(false)}
    >
      <motion.div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "#000",
          position: "absolute",
        }}
        variants={liquidFillVariants}
        initial="rest"
        animate={isHover ? "hover" : "rest"}
        transition={{ duration: 0.5 }}
      />
      <motion.div
        style={{
          position: "absolute",
          top: "48%",
          left: "51%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <motion.div
          animate={{ color: isHover ? "#FFFFFF" : "#FFFFFF" }}
          transition={{ duration: 0.4 }}
        >
          <Nav.Link as={Link} to={props.link}>
            {props.text}
          </Nav.Link>
        </motion.div>
      </motion.div>
    </motion.button>
  ) : (
    <motion.button
      style={{
        width: "120px",
        height: "60px",
        padding: 0,
        backgroundColor: "#ff5000",
        border: "transparent",
        borderRadius: "110px",
        cursor: "pointer",
        overflow: "hidden",
        position: "relative",
      }}
      onHoverStart={() => setHover(true)}
      onHoverEnd={() => setHover(false)}
    >
      <motion.div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "#fff",
          position: "absolute",
        }}
        variants={liquidFillVariants}
        initial="rest"
        animate={isHover ? "hover" : "rest"}
        transition={{ duration: 0.5 }}
      />
      <motion.div
        style={{
          position: "absolute",
          top: "48%",
          left: "51%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <motion.div
          animate={{ color: isHover ? "#000000" : "#ffffff" }}
          transition={{ duration: 0.4 }}
        >
          <Nav.Link as={Link} to={props.link}>
            {props.text}
          </Nav.Link>
        </motion.div>
      </motion.div>
    </motion.button>
  );
};

export default LiquidButton;
