import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { BsSearchHeart } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import "./NavBar.css";

const SearchBar = () => {
  const navigate = useNavigate();
  const { keyword: urlKeyword } = useParams();
  const [keyword, setKeyword] = useState(urlKeyword);

  const handleSearchChange = (e) => {
    setKeyword(e.target.value);
  };
  const submitHandler = (e) => {
    e.preventDefault();
    if (keyword.trim()) {
      navigate(`/search/${keyword}`);
    } else {
      navigate("/");
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      submitHandler(e);
    }
  };
  return (
    <Form className="d-flex searchbar" onSubmit={submitHandler}>
      <Form.Control
        type="text"
        name="q"
        placeholder="검색.."
        className="me-2 searchbox"
        aria-label="Search"
        onChange={handleSearchChange}
        onKeyDown={handleKeyPress}
        value={keyword}
      />
      <Button type="sumbit" className="searchbutton">
        <BsSearchHeart />
      </Button>
    </Form>
  );
};

export default SearchBar;
