import { yupResolver } from "@hookform/resolvers/yup";
import { useInView } from "framer-motion";
import React, { useRef, useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useSendContactMutation } from "../slices/usersApiSlice";
import "./ContactForm.css";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("이메일 주소를 입력해주세요")
    .required("이메일을 입력해주세요"),
  name: yup.string().required("이름을 입력해주세요"),
  content: yup.string().required("내용을 입력해주세요"),
});

const ContactForm = () => {
  const {
    register: formRegister,
    handleSubmit,
    formState: { errors, isSubmitting },
    trigger,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [buttonText, setButtonText] = useState("보내기");

  const [sendContact] = useSendContactMutation();

  const onSubmit = async ({ name, email, content }) => {
    try {
      setButtonText("보내는중...");
      await sendContact({ name, email, content }).unwrap();
      toast.success("문의사항이 성공적으로 발송되었습니다");
      setButtonText("보내기");
      reset();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  return (
    <div
      className="contact-container"
      ref={ref}
      style={{
        transform: isInView ? "none" : "none",
        opacity: isInView ? 1 : 0,
        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.3s",
      }}
    >
      <Form onSubmit={handleSubmit(onSubmit)} className="contact-form">
        <Form.Group className="mb-3" controlId="validationCustomName">
          <FloatingLabel label="이름" className="mb-3">
            <Form.Control
              {...formRegister("name")}
              type="text"
              id="name"
              autoComplete="name"
              placeholder="이름"
              onChange={(e) => {
                formRegister("name").onChange(e);
                trigger("name");
              }}
            />
          </FloatingLabel>
          {errors.name && <p className="error">{errors.name.message}</p>}
        </Form.Group>
        <Form.Group className="mb-3" controlId="validationCustomEmail">
          <FloatingLabel label="이메일" className="mb-3">
            <Form.Control
              {...formRegister("email")}
              type="email"
              id="email"
              placeholder="이메일"
              autoComplete="email"
              onChange={(e) => {
                formRegister("email").onChange(e);
                trigger("email");
              }}
            />
          </FloatingLabel>
          {errors.email && <p className="error">{errors.email.message}</p>}
        </Form.Group>
        <Form.Group className="mb-3" controlId="validationCustomInput">
          <FloatingLabel label="내용" className="mb-3">
            <Form.Control
              {...formRegister("content")}
              as="textarea"
              id="content"
              placeholder="내용"
              onChange={(e) => {
                formRegister("content").onChange(e);
                trigger("content");
              }}
              style={{ height: "200px", resize: "none" }}
            />
          </FloatingLabel>
          {errors.content && <p className="error">{errors.content.message}</p>}
        </Form.Group>
        <button className="contactBtn" type="submit">
          {buttonText}
        </button>
      </Form>
    </div>
  );
};

export default ContactForm;
