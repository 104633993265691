// import { LinkContainer } from 'react-router-bootstrap';
import { Button, Col, Container, Nav, Row, Table } from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import {
  useDeleteForumPostMutation,
  useGetForumPostsQuery,
} from "../../slices/forumPostsApiSlice";
import "./BlogPostList.css";

import GoToTop from "../../components/GoToTop";
import Paginate from "../../components/Paginate";
import { extractImageUrls } from "../../components/extractImageUrls";
import { useDeleteImageMutation } from "../../slices/imageApiSlice";

const ForumPostList = () => {
  const { pageNumber } = useParams();
  const { data, isLoading, error, refetch } = useGetForumPostsQuery({
    pageNumber,
  });
  const [deleteForumPost, { isLoading: loadingDelete }] =
    useDeleteForumPostMutation();
  const [deleteImage] = useDeleteImageMutation();

  const deleteHandler = async (id, content) => {
    if (window.confirm("해당 글을 삭제하시겠습니까?")) {
      try {
        await deleteForumPost(id);
        const imageUrls = extractImageUrls(content);
        for (const imageUrl of imageUrls) {
          const filename = imageUrl.split("/").pop();
          try {
            await deleteImage(filename).unwrap();
            // toast.success("Image deleted successfully");
          } catch (err) {
            toast.error(err?.data?.message || "Failed to delete image");
          }
        }
        refetch();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  return (
    <Container className="admin-bloglist-container">
      <Row className="align-items-center mb-5">
        <Col>
          <h1 className="text-start">전체 포럼 글</h1>
        </Col>
      </Row>

      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <Table
            striped
            bordered
            hover
            responsive
            className="table-sm"
            variant="dark"
          >
            <thead>
              <tr>
                <th>ID</th>
                <th>TITLE</th>
                <th>AUTHOR</th>
                <th>DATE</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.forumPosts.map((forumPost) => (
                <tr key={forumPost._id}>
                  <td>{forumPost._id}</td>
                  <td>{forumPost.title}</td>
                  <td>{forumPost.user.username}</td>
                  <td>{forumPost.createdAt}</td>
                  <td>
                    <Nav.Link
                      as={Link}
                      to={`/forum-post/${forumPost._id}/edit`}
                    >
                      <Button variant="light" className="btn-sm mx-2">
                        <FaEdit />
                      </Button>
                    </Nav.Link>
                    <Button
                      variant="danger"
                      className="btn-sm"
                      onClick={() =>
                        deleteHandler(forumPost._id, forumPost.content.ops)
                      }
                    >
                      <FaTrash style={{ color: "white" }} />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Paginate
            pages={data.pages}
            page={data.page}
            curLocation="admin/forum-post-list"
          />
        </>
      )}
      <GoToTop />
    </Container>
  );
};

export default ForumPostList;
