import React from "react";
import { Button } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import { useGetBlogPostsQuery } from "../slices/blogPostsApiSlice";
import { useGetForumPostsQuery } from "../slices/forumPostsApiSlice";
import Header from "./Header";
import Loader from "./Loader";
import Paginate from "./Paginate";
import PostRenderer from "./PostRenderer";

const SearchResultsScreen = () => {
  const { pageNumber, keyword } = useParams();

  const navigate = useNavigate();

  const { data: blogPostsData, isLoading: isLoadingBlogPosts } =
    useGetBlogPostsQuery({ keyword }, { enabled: false }); // 'enabled: false' prevents automatic refetching

  const { data: forumPostsData, isLoading: isLoadingForumPosts } =
    useGetForumPostsQuery({ keyword }, { enabled: false });

  const combinedPosts = [
    ...(blogPostsData || []), // Default to an empty array if blogPosts is falsy
    ...(forumPostsData?.forumPosts || []), // Default to an empty array if forumPosts is falsy
  ];
  combinedPosts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  const pageSize = 8;
  let currentPage = parseInt(pageNumber, 10);
  const totalPosts = combinedPosts.length;
  const totalPages = Math.ceil(totalPosts / pageSize);
  if (isNaN(currentPage)) {
    currentPage = 1; // Default to 1 if pageNumber is NaN
  }
  const paginatedPosts = combinedPosts.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  if (isLoadingBlogPosts || isLoadingForumPosts) {
    return <Loader />;
  }

  return (
    <div style={{ paddingBottom: "150px" }} className="search-container">
      <Header title="검색 결과" text={`'${keyword}' 검색 결과입니다`} />
      <div className="text-start">
        <Button onClick={() => navigate(-1)} className="goBackBtn">
          <FaArrowLeft className="me-2" /> 돌아가기
        </Button>
      </div>
      <div className="search-posts">
        {paginatedPosts.map((post) => (
          <PostRenderer key={post._id} post={post} />
        ))}
      </div>
      <Paginate
        pages={totalPages}
        page={currentPage}
        curLocation="search"
        keyword={keyword}
      />
    </div>
  );
};

export default SearchResultsScreen;
