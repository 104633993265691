export const extractImageUrls = (contentOps) => {
  const imageUrls = [];

  contentOps.forEach((op) => {
    if (op.insert && typeof op.insert === "object" && op.insert.image) {
      imageUrls.push(op.insert.image);
    }
  });

  return imageUrls;
};
