import React, { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import {
  useGetUserDetailsQuery,
  useUpdateUserMutation,
  useUploadUserImageMutation,
} from "../../slices/usersApiSlice";

const UserEdit = () => {
  const { id: userId } = useParams();
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [bio, setBio] = useState("");
  const [image, setImage] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);

  const {
    data: user,
    isLoading,
    error,
    refetch,
  } = useGetUserDetailsQuery(userId);

  const [updateUser, { isLoading: loadingUpdate }] = useUpdateUserMutation();

  const [uploadUserImage, { isLoading: loadingUpload }] =
    useUploadUserImageMutation();

  const navigate = useNavigate();

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      await updateUser({ userId, name, email, username, bio, image, isAdmin });
      toast.success("성공적으로 수정되었습니다");
      refetch();
      navigate("/admin/user-list");
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const uploadImageHandler = async (e) => {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    try {
      const res = await uploadUserImage(formData).unwrap();
      toast.success(res.message);
      setImage(res.image);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user.email);
      setUsername(user.username);
      setBio(user.bio);
      setImage(user.image);
      setIsAdmin(user.isAdmin);
    }
  }, [user]);

  return (
    <>
      {loadingUpdate && <Loader />}
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <div className="mx-auto px-5">
          <Header title="해당 유저 수정" text={`어떻게 수정하실건가요?`} />
          <Container>
            <Form className="new-blog-container" onSubmit={submitHandler}>
              <Form.Group controlId="name" className="py-2">
                <Form.Label>이름</Form.Label>
                <Form.Control
                  type="name"
                  placeholder="제목을 입력해주세요"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="email" className="py-2">
                <Form.Label>이메일</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="제목을 입력해주세요"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="username" className="py-2">
                <Form.Label>닉네임</Form.Label>
                <Form.Control
                  type="username"
                  placeholder="제목을 입력해주세요"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="bio" className="py-2">
                <Form.Label>자기소개</Form.Label>
                <Form.Control
                  type="bio"
                  placeholder="제목을 입력해주세요"
                  value={bio}
                  onChange={(e) => setBio(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="formImage" className="py-2">
                <Form.Label>이미지</Form.Label>
                <Form.Control
                  type="file"
                  onChange={uploadImageHandler}
                  name="formImage"
                />
              </Form.Group>
              <Form.Group
                className="my-2 mb-5"
                controlId="isadmin"
                style={{ color: "#9ca8b4" }}
              >
                <Form.Check
                  type="checkbox"
                  label="관리자"
                  checked={isAdmin}
                  onChange={(e) => setIsAdmin(e.target.checked)}
                ></Form.Check>
              </Form.Group>
              <div className="new-post-btn-container me-5 d-flex justify-content-center align-items-center">
                <button className="new-post-btn">작성하기</button>
              </div>
            </Form>
          </Container>
        </div>
      )}
    </>
  );
};

export default UserEdit;
