import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import useCountdown from "../hooks/useCountdown";
import {
  useGetProfileQuery,
  useProfileMutation,
  useSendVerificationCodeMutation,
} from "../slices/usersApiSlice";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("이메일 주소를 입력해주세요")
    .required("이메일을 입력해주세요"),
  verificationCode: yup.string().required("인증 번호를 입력해주세요"),
});

const ChangeEmailForm = ({ onClose }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const { data: user, refetch } = useGetProfileQuery(userInfo._id);
  const [updateProfile] = useProfileMutation();
  const [verificationCodeRequestPending, setVerificationCodeRequestPending] =
    useState(false);
  const {
    secondsLeft: verificationCodeCooldownSecondsLeft,
    start: startVerificationCodeCooldown,
  } = useCountdown();

  const [verificationCode] = useSendVerificationCodeMutation();

  const {
    register: formRegister,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
    trigger,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const emailValue = watch("email");

  const onSubmit = async ({ email }) => {
    try {
      await updateProfile({ email });
      toast.success("성공적으로 수정되었습니다");
      onClose();
      refetch();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const requestVerificationCode = async ({ email }) => {
    setVerificationCodeRequestPending(true);

    try {
      await verificationCode(email).unwrap();
      startVerificationCodeCooldown(180);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    } finally {
      setVerificationCodeRequestPending(false);
    }
  };

  return (
    <Form className="new-password-container" onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3" controlId="newEmail">
        <Form.Label>이메일 변경</Form.Label>
        <Form.Control
          {...formRegister("email")}
          type="email"
          placeholder="변경하실 이메일을 적어주세요"
          onChange={(e) => {
            formRegister("email").onChange(e);
            trigger("email");
          }}
          autoFocus
        />
        {errors.email && <p className="error mt-1">{errors.email.message}</p>}
      </Form.Group>

      <Form.Group className="mb-4" controlId="confirmPassword">
        <Form.Label>이메일 인증 번호</Form.Label>
        <Form.Control
          type="verificationCode"
          placeholder="인증 번호를 입력해주세요"
          {...formRegister("verificationCode")}
        />
        <button
          className="send-verification-btn mt-2"
          id="button-send-verification-code"
          disabled={
            verificationCodeRequestPending ||
            verificationCodeCooldownSecondsLeft > 0 ||
            !emailValue
          }
          onClick={() => requestVerificationCode({ email: emailValue })}
        >
          전송
          {verificationCodeCooldownSecondsLeft > 0 &&
            ` (${verificationCodeCooldownSecondsLeft})`}
        </button>
        {errors.verificationCode && (
          <p className="error mt-1">{errors.verificationCode.message}</p>
        )}
      </Form.Group>

      <button
        type="submit"
        isLoading={isSubmitting}
        className="new-password-btn"
      >
        변경하기
      </button>
    </Form>
  );
};

export default ChangeEmailForm;
