import React from "react";

const formatDate = (date) => {
  const postDate = new Date(date);

  return postDate.toLocaleDateString("ko-KR", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

const BlogPost = ({
  id,
  title,
  createdAt,
  category,
  abstract,
  image,
  content,
}) => {
  const imageUrl = image ? `${image}` : null;

  return (
    <article className="blog-post">
      <div>
        <time className="post-date">{formatDate(createdAt)}</time>
        <h2 className="post-title">{title}</h2>
        <p className="post-summary">{abstract}</p>
        <span className="post-category py-2 px-3">{category}</span>
      </div>
      <div>
        {imageUrl && <img src={imageUrl} alt={title} className="post-image" />}
      </div>
    </article>
  );
};

export default BlogPost;
