import { useInView } from "framer-motion";
import React, { useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import LiquidButton from "./LiquidButton";
import "./PremiumBanner.css";

const PremiumBanner = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  return (
    <section id="premium-banner" className="premium-banner">
      <Container>
        <Row className="align-items-end">
          <Col xs={12} md={7} xl={7}>
            <h2>Premium</h2>
            <h3 className="title">더 알고싶으시다면</h3>
            <LiquidButton color="bright" text="준비중" link="/" />
          </Col>
          <Col xs={12} md={5} xl={5}>
            <p
              ref={ref}
              style={{
                transform: isInView ? "none" : "translateX(-200px)",
                opacity: isInView ? 1 : 0,
                transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s",
              }}
            >
              삶이 헷갈릴 때, 답을 찾고 싶다면 연락해보세요. 타로 마스터와의
              대화를 통해 여러분의 고민을 해결해 드립니다. 지금, 더 깊은
              통찰력을 얻고 싶다면 언제든 연락해주세요.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PremiumBanner;
