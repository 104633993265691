import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  userInfo: localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      state.userInfo = action.payload;
      localStorage.setItem("userInfo", JSON.stringify(action.payload));
      const expirationTime = new Date().getTime() + 24 * 60 * 60 * 1000; // 1 day
      localStorage.setItem("expirationTime", expirationTime);
    },
    logout: (state, action) => {
      state.userInfo = null;
      localStorage.removeItem("userInfo");
      localStorage.removeItem("expirationTime");
    },
    likeForumPostLocal: (state, action) => {
      const { postId } = action.payload;
      if (state.userInfo) {
        // Initialize likedForumPosts if it doesn't exist
        if (!state.userInfo.likedForumPosts) {
          state.userInfo.likedForumPosts = [];
        }
        
        // Append postId to the array
        state.userInfo.likedForumPosts.push(postId);

        // Update localStorage
        localStorage.setItem("userInfo", JSON.stringify(state.userInfo));
      }
    },
    likeBlogPostLocal: (state, action) => {
      const { postId } = action.payload;
      if (state.userInfo) {
        // Initialize likedForumPosts if it doesn't exist
        if (!state.userInfo.likedBlogPosts) {
          state.userInfo.likedBlogPosts = [];
        }
        
        // Append postId to the array
        state.userInfo.likedBlogPosts.push(postId);

        // Update localStorage
        localStorage.setItem("userInfo", JSON.stringify(state.userInfo));
      }
    },
  },
});

export const { setCredentials, logout, likeForumPostLocal, likeBlogPostLocal } = authSlice.actions;

export default authSlice.reducer;
