import ImageUploader from "quill-image-uploader";
import React, { useCallback, useEffect, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import { useDeleteImageMutation } from "../slices/imageApiSlice";
import "./EditorQuill.css";

Quill.register("modules/imageUploader", ImageUploader);

const EditorQuill = ({
  placeholder,
  onContentChange,
  onImageUrlsChange,
  previousContent,
}) => {
  const [content, setContent] = useState(""); // To store the delta format
  const [deleteImage] = useDeleteImageMutation();

  const extractImageUrls = useCallback((content) => {
    const urls = new Set();
    if (content && content.ops) {
      for (const op of content.ops) {
        if (op.insert && op.insert.image) {
          urls.add(op.insert.image);
        }
      }
    }
    return urls;
  }, []);
  const [imageUrls, setImageUrls] = useState(new Set());

  const handleContentChange = useCallback(
    async (content, delta, source, editor) => {
      setContent(editor.getContents());

      if (source === "user") {
        const currentContents = editor.getContents();
        const currentImageUrls = new Set(
          currentContents.ops
            .filter((op) => op.insert && op.insert.image)
            .map((imageOp) => imageOp.insert.image)
        );

        // Identify deleted images by comparing the new set of image URLs with the previous one
        const deletedImageUrls = [...imageUrls].filter(
          (url) => !currentImageUrls.has(url)
        );

        // Update the Set of image URLs with the latest ones
        setImageUrls(currentImageUrls);
        if (onImageUrlsChange) {
          onImageUrlsChange(deletedImageUrls); // Call the callback function with the current set of image URLs
        }
      }

      if (onContentChange) {
        onContentChange(editor.getContents());
      }
    },
    [onContentChange, deleteImage, imageUrls]
  );

  useEffect(() => {
    if (previousContent) {
      setContent(previousContent);
      setImageUrls(extractImageUrls(previousContent));
    }
  }, [previousContent, extractImageUrls]);

  return (
    <div className="editor-container">
      <ReactQuill
        theme="snow"
        onChange={handleContentChange}
        value={content}
        modules={EditorQuill.modules}
        formats={EditorQuill.formats}
        bounds={".app"}
        placeholder={placeholder}
        className="my-5"
      />
    </div>
  );
};

// Define Quill modules and formats as static properties
EditorQuill.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
  },
  imageUploader: {
    upload: (file) => {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("image", file);

        fetch("/api/upload", {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((result) => {
            resolve(result.image);
          })
          .catch((error) => {
            reject("Upload failed");
            console.error("Error:", error);
          });
      });
    },
  },
};

EditorQuill.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

export default EditorQuill;
