import React, { Component, Fragment } from "react";
import BarajaJS from "react-baraja-js";
import { smallestCards } from "../data/constants";
import "./SpreadableSM.css";
// import { useLocation } from 'react-router-dom';
import { Navigate } from "react-router-dom";
import { Cards } from "../data/constants"; // Assuming 'Cards' is your original, unshuffled array

class SpreadableXS extends Component {
  constructor() {
    super();
    this.state = {
      fan: {},
      // mycards: [],
      navigateToCard: false, // New state property
      selectedCard: null, // Holds the card to navigate with
    };
  }
  fanEvent() {
    this.setState({
      fan: {
        direction: "right",
        easing: "ease-out",
        origin: {
          x: 50,
          y: 200,
        },
        speed: 500,
        range: 15,
        translation: 30,
        center: false,
      },
    });
  }
  shuffleArray = (arr) => {
    let array = arr.slice(); // Create a shallow copy to avoid modifying the original array
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }
  componentDidMount() {
    // console.log(Cards); // Debugging line to check Cards content
    this.autoClickFunction();
    // const shuffledSmallestCards = this.shuffleArray(Cards).slice(0, 7);
    // this.setState({ mycards: shuffledSmallestCards });
  }
  
  // componentDidMount() {
  //   this.autoClickFunction();
  // }

  // componentDidUpdate(prevProps) {
  //   console.log(prevProps.currentPath )
  //   console.log(this.props.currentPath)
  //   console.log('??')
  //   console.log(this.props.from )
  //   console.log(prevProps.from)
  //   if (this.props.from !== prevProps.from) {
  //     // this.handleClick();
  //     console.log('mh')
  //   }
  // }
  

  autoClickFunction = () => {
    // Your button's onClick function logic here
    this.fanEvent();
  };

  handleClick = (title) => {
    // Update the navigate state along with the selected card
    // this.setState({
    //   navigateToCard: true,
    //   selectedCard: title,
    // });
    // console.log("Card clicked:", title);
    this.props.navigate("/selected-card", { state: { userCard: title, tarotType: this.props.tarotType, from: this.props.from } })

  };

  handleParentClick = (event) => {
    let targetElement = event.target;

    // If the clicked element is the img itself or any of its descendants
    if (targetElement.tagName !== 'IMG') {
        targetElement = targetElement.closest('li').querySelector('img');
    }

    if (targetElement && targetElement.getAttribute('data-title')) {
        const title = targetElement.getAttribute('data-title');
        // console.log('Extracted title:', title);
        this.handleClick(title);
    } else {
        console.log("Title not found on clicked element.");
    }
};


  

  render() {
    const { fan, navigateToCard, selectedCard } = this.state;
    const shuffledSmallestCards = this.shuffleArray(Cards).slice(0, 7);

    // if (navigateToCard) {
    //   // Navigate when navigateToCard is true
    //   return (
    //     <Navigate
    //       to="/selected-card"
    //       replace={true}
    //       state={{ userCard: selectedCard }}
    //     />
    //   );
    // }
    // const handleClick = (title) => {
    //   this.props.onCardSelect(title);
    //   this.props.navigate("/selected-card", { state: { userCard: title } });

    // };
    return (
      <main className="card-box-sm" onClick={this.handleParentClick}>
        <Fragment>
          <BarajaJS fan={fan}>
            {shuffledSmallestCards.map(({ imageSrc, title, details }, index) => {
              return (
                <Fragment key={index}>
                  <img
                    alt={title}
                    src={imageSrc}
                    style={{ width: "160px", height: "230px" }}
                    data-title={title}
                    // onClick={() => this.handleClick(title)}
                  />
                </Fragment>
              );
            })}
          </BarajaJS>
          <button
            style={{ display: "none" }}
            id="fan-button"
            onClick={this.autoClickFunction}
          >
            Fan right
          </button>
        </Fragment>
      </main>
    );
  }
}

export default SpreadableXS;
