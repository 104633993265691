import { REPORTS_URL } from "../constants";
import { apiSlice } from "./apiSlice";

export const reportsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getReports: builder.query({
      query: () => ({
        url: REPORTS_URL,
      }),
      providesTags: ["Report"],
      keepUnusedDataFor: 5,
    }),
    addReport: builder.mutation({
      query: (newReport) => ({
        url: REPORTS_URL,
        method: "POST",
        body: newReport,
      }),
      invalidatesTags: ["Report"],
    }),
    deleteReport: builder.mutation({
      query: (reportId) => ({
        url: `${REPORTS_URL}/${reportId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetReportsQuery,
  useAddReportMutation,
  useDeleteReportMutation,
} = reportsApiSlice;
