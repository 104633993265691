import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GoToTop from "../components/GoToTop";
import Header from "../components/Header";
import QuestionIcon from "../components/QuestionIcon";
import RevealButton from "../components/RevealButton";
import Spreadable from "../components/Spreadable";
import SpreadableSM from "../components/SpreadableSM";
import SpreadableXS from "../components/SpreadableXS";
const Lover = () => {
  const [selectedCard, setSelectedCard] = useState("");
  const navigate = useNavigate();
  const handleCardSelected = (title) => {
    setSelectedCard(title);
  };
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isSmallestScreen, setIsSmallestScreen] = useState(false);
const location = useLocation();
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setIsSmallScreen(screenWidth < 1024);
      setIsSmallestScreen(screenWidth < 692);
        };

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div style={{ paddingBottom: "150px" }}>
      <Header
        title="애정 운세"
        subtitle="생각을 비우고 편안한 마음으로 카드 한 장을 선택해주세요."
        text={`
        타로 카드는 사랑과 인간 관계의 미묘한 측면을 탐색하는 데 이상적인 도구입니다. 
        애정 운세 카드가 전하는 메시지를 통해, 궁금한 애정 관계에 대해 새로운 통찰과 영감을 얻을 수 있습니다.
      `}
      />
      {/* <QuestionIcon placement="right" /> */}
      {isSmallestScreen ? (
        <SpreadableXS onCardSelect={handleCardSelected} navigate={navigate} tarotType="lover" from={location.pathname} />
      ) : isSmallScreen ? (
        <SpreadableSM onCardSelect={handleCardSelected} navigate={navigate} tarotType="lover" from={location.pathname} />
      ) : (
        <Spreadable onCardSelect={handleCardSelected} navigate={navigate} tarotType="lover" from={location.pathname} />
      )}
      {/* <div
        style={{
          position: "absolute",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <RevealButton
          s1="open"
          onClick={() =>
            navigate("/selected-card", {
              state: { userCard: selectedCard, tarotType: "lover" },
            })
          }
        />
      </div> */}
      <GoToTop />
    </div>
  );
};

export default Lover;
