import React, { useState } from "react";
import { motion } from "framer-motion";
import "./RevealButton.css";

const RevealButton = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div className="link-animation">
      <div
        className="container"
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
        onClick={props.onClick}
      >
        {props.s1.split("").map((letter, i) => (
          <div className="letter-container" key={i}>
            <div>{letter}</div>
            <motion.div
              initial={{ left: "-100%" }}
              animate={{ left: isHovered ? "0%" : "-100%" }}
              transition={{
                duration: isHovered ? 0.7 : 0.5,
                ease: [0.7, 0, 0.3, 1],
              }}
            >
              {letter.toUpperCase()}
            </motion.div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RevealButton;
