import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <Container>
        <Row className="align-item-center">
          <h4>타로게이트</h4>
        </Row>
        <Row className="align-item-center">
          <Col>
            <h5>FOLLOW US</h5>
            <a href="https://www.youtube.com/@tarotx3">YouTube</a>
            <br />
          </Col>
          <Col>
            <h5>LEARN</h5>
            <a href="/about">About us</a>
          </Col>
          <Col>
            <h5>MORE</h5>
            <a href="/contact">Contact Us</a>
          </Col>
        </Row>
        <hr />
        <p>CopyRight 2024. All Right Reserved</p>
      </Container>
    </div>
  );
};

export default Footer;
