import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import {
  useGetProfileQuery,
  useProfileMutation,
} from "../slices/usersApiSlice";
import "./ChangePasswordForm.css";

const validationSchema = yup.object({
  password: yup
    .string()
    .min(8, "비밀번호는 최소 8자리를 입력해주세요")
    .matches(/[a-z]+/, "비밀번호는 최소 영문 소문자 한자리를 입력해주세요")
    .matches(/[A-Z]+/, "비밀번호는 최소 영문 대문자 한자리를 입력해주세요")
    .matches(/[@$!%*#?&]+/, "비밀번호는 최소 특수문자 한자리를 입력해주세요")
    .matches(/\d+/, "비밀번호는 최소 숫자 한자리를 입력해주세요")
    .required("비밀번호를 입력해주세요"),
  confirmPassword: yup
    .string()
    .required("비밀번호를 한번 더 입력해주세요")
    .oneOf([yup.ref("password")], "비밀번호가 일치하지 않습니다"),
});

const ChangePasswordForm = ({ onClose }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const { data: user, refetch } = useGetProfileQuery(userInfo._id);
  const [updateProfile] = useProfileMutation();
  const {
    register: formRegister,
    handleSubmit,
    formState: { errors, isSubmitting },
    trigger,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async ({ password }) => {
    try {
      await updateProfile({ password });
      toast.success("성공적으로 수정되었습니다");
      onClose();
      refetch();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <Form className="new-password-container" onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3 visually-hidden" controlId="username">
        <Form.Label>Username</Form.Label>
        <Form.Control
          type="text"
          autoComplete="username"
          placeholder="Enter your username"
          defaultValue={userInfo?.username}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="newPassword">
        <Form.Label>비밀번호 변경</Form.Label>
        <Form.Control
          {...formRegister("password")}
          type="password"
          placeholder="새로운 비밀번호를 작성해주세요"
          autoComplete="new-password"
          onChange={(e) => {
            formRegister("password").onChange(e);
            trigger("password");
          }}
          autoFocus
        />
        {errors.password && (
          <p className="error mt-1">{errors.password.message}</p>
        )}
      </Form.Group>

      <Form.Group className="mb-4" controlId="confirmPassword">
        <Form.Label>확인하기</Form.Label>
        <Form.Control
          {...formRegister("confirmPassword")}
          type="password"
          placeholder="새로운 비밀번호를 한번 더 작성해주세요"
          autoComplete="new-password"
          onChange={(e) => {
            formRegister("confirmPassword").onChange(e);
            trigger("confirmPassword");
          }}
        />
        {errors.confirmPassword && (
          <p className="error mt-1">{errors.confirmPassword.message}</p>
        )}
      </Form.Group>

      <button
        type="submit"
        className="new-password-btn"
        disabled={isSubmitting}
      >
        변경하기
      </button>
    </Form>
  );
};

export default ChangePasswordForm;
