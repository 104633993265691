import { yupResolver } from "@hookform/resolvers/yup";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineLogin } from "react-icons/ai";
import { BsFillShieldLockFill } from "react-icons/bs";
import { FaUserShield } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { MdEmail } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import GoToTop from "../components/GoToTop";
import Loader from "../components/Loader";
import useCountdown from "../hooks/useCountdown";
import fortune from "../imgs/fortune.mp4";
import { setCredentials } from "../slices/authSlice";
import {
  useGoogleLoginMutation,
  useNaverLoginMutation,
  useRegisterMutation,
  useSendVerificationCodeMutation,
} from "../slices/usersApiSlice";

const RegisterForm = (props) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isMdScreen, setIsMdScreen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 550);
      setIsMdScreen(window.innerWidth < 1024);
    };

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [verificationCodeRequestPending, setVerificationCodeRequestPending] =
    useState(false);
  const [showVerificationCodeSentText, setShowVerificationCodeSentText] =
    useState(false);
  const {
    secondsLeft: verificationCodeCooldownSecondsLeft,
    start: startVerificationCodeCooldown,
  } = useCountdown();

  const [verificationCode] = useSendVerificationCodeMutation();

  const [showVerificationInput, setShowVerificationInput] = useState(false);

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("이메일 주소를 입력해주세요")
      .required("이메일을 입력해주세요"),
    password: yup
      .string()
      .min(8, "비밀번호는 최소 8자리를 입력해주세요")
      .matches(/[a-z]+/, "비밀번호는 최소 영문 소문자 한자리를 입력해주세요")
      .matches(/[A-Z]+/, "비밀번호는 최소 영문 대문자 한자리를 입력해주세요")
      .matches(/[@$!%*#?&]+/, "비밀번호는 최소 특수문자 한자리를 입력해주세요")
      .matches(/\d+/, "비밀번호는 최소 숫자 한자리를 입력해주세요")
      .required("비밀번호를 입력해주세요"),
    name: yup.string().required("이름을 입력해주세요"),
    username: yup.string().required("닉네임을 입력해주세요"),
    verificationCode: yup.string().required("인증 번호를 입력해주세요"),
  });
  const {
    register: formRegister,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
    trigger,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const emailValue = watch("email");
  const nameValue = watch("name");
  const userNameValue = watch("username");
  const passwordValue = watch("password");
  const verificationCodeValue = watch("verificationCode");

  const [register, { isLoading }] = useRegisterMutation();

  const { userInfo } = useSelector((state) => state.auth);

  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const redirect = sp.get("redirect") || "/";

  const [naverLoginCode] = useNaverLoginMutation();
  const [googleLoginCode] = useGoogleLoginMutation();

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [navigate, redirect, userInfo]);

  useEffect(() => {
    const naverLogin = new window.naver.LoginWithNaverId({
      clientId: process.env.REACT_APP_NAVER_CLIENT_ID,
      callbackUrl: "https://thetarotgate.com/register",
      isPopup: false,
      loginButton: { color: "green", type: 1, height: 27 }, // Customize login button
    });

    naverLogin.init();
    const hash = window.location.hash;
    // Parse the URL and extract the code after the component mounts
    const params = new URLSearchParams(hash.substr(1));
    const accessToken = params.get("access_token");

    const handleNaverLogin = async (accessToken) => {
      try {
        const res = await naverLoginCode(accessToken).unwrap();
        dispatch(setCredentials({ ...res }));
        navigate(redirect);
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    };
    if (accessToken) {
      handleNaverLogin(accessToken);
    }
  }, []);

  useEffect(() => {
    window.google?.accounts.id.initialize({
      client_id: `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`,
      callback: handleCredentialResponse,
    });
  }, []);

  const handleCredentialResponse = async (response) => {
    const accessToken = response.credential;
    // Send this response.credential (JWT ID token) to your backend for verification and user handling
    try {
      const res = await googleLoginCode(accessToken).unwrap();
      dispatch(setCredentials({ ...res }));
      navigate(redirect);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };
  const handleGoogleLogin = () => {
    window.google?.accounts.id.prompt();
  };

  const onSubmit = async (data) => {
    try {
      setShowVerificationCodeSentText(false);
      setShowVerificationInput(false);
      const res = await register(data).unwrap();
      dispatch(setCredentials({ ...res }));
      navigate(redirect);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const requestVerificationCode = async ({ email }) => {
    setShowVerificationCodeSentText(false);
    setVerificationCodeRequestPending(true);
    setShowVerificationInput(true);

    try {
      await verificationCode(email).unwrap();
      setShowVerificationCodeSentText(true);
      startVerificationCodeCooldown(180);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    } finally {
      setVerificationCodeRequestPending(false);
    }
  };

  return (
    <div className="loginPage my-flex">
      {isLoading && <Loader />}
      <div className="registerContainer my-flex">
        <div className="videoDiv">
          <video
            src={fortune}
            autoPlay={isSmallScreen ? false : true}
            muted
            loop
          ></video>
          <div className="loginTextDiv">
            <h2 className="loginTitle">일상 속의 타로 가이드</h2>
            <p className="loginText">
              매일을 위한 타로 가이드, 지금 바로 시작하세요
            </p>
          </div>
          <div className="loginFooterDiv my-flex">
            <span className="text">계정이 이미 있으신가요?</span>
            <Link to={"/login"}>
              <button onClick={props.toggleForm} className="loginBtn">
                로그인
              </button>
            </Link>
          </div>
        </div>

        <div className="registerFormDiv my-flex">
          <div className="loginHeaderDiv">
            <h3>당신의 이야기를 들려주세요!</h3>
          </div>
          {showVerificationCodeSentText && (
            <p className="error mt-1">
              이메일 인증 번호가 발송되었습니다. 이메일을 확인해주세요.
            </p>
          )}
          <form
            action=""
            className="registerForm my-grid"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="loginInputDiv">
              <label htmlFor="email">이메일</label>
              <div className="loginInput my-flex justify-content-center">
                <MdEmail className="loginIcon" />
                <input
                  {...formRegister("email")}
                  type="email"
                  id="email"
                  autoComplete="email"
                  placeholder="이메일을 입력해주세요"
                  onChange={(e) => {
                    formRegister("email").onChange(e);
                    trigger("email");
                  }}
                />
              </div>
              {errors.email && <p className="error">{errors.email.message}</p>}
            </div>
            <div className="loginInputDiv">
              <label htmlFor="verificationCode">이메일 인증 번호</label>
              <div className="loginInput my-flex justify-content-center">
                <BsFillShieldLockFill className="loginIcon" />
                {showVerificationInput ? (
                  <input
                    type="number"
                    id="verificationCode"
                    placeholder="인증 번호를 입력해주세요"
                    {...formRegister("verificationCode")}
                  />
                ) : (
                  <></>
                )}
                {/* <input
                  type="number"
                  id="verificationCode"
                  placeholder="인증 번호를 입력해주세요"
                  {...formRegister("verificationCode")}
                /> */}
                <button
                  className="send-verification-btn"
                  id="button-send-verification-code"
                  disabled={
                    verificationCodeRequestPending ||
                    verificationCodeCooldownSecondsLeft > 0 ||
                    !emailValue
                    // !nameValue ||
                    // !userNameValue ||
                    // !passwordValue
                  }
                  onClick={() => requestVerificationCode({ email: emailValue })}
                >
                  전송
                  {verificationCodeCooldownSecondsLeft > 0 &&
                    ` (${verificationCodeCooldownSecondsLeft})`}
                </button>
              </div>
              {errors.verificationCode && (
                <p className="error">{errors.verificationCode.message}</p>
              )}
            </div>
            <div className="loginInputDiv">
              <label htmlFor="name">이름</label>
              <div className="loginInput my-flex justify-content-center">
                <FaUserShield className="loginIcon" />
                <input
                  {...formRegister("name")}
                  type="text"
                  id="name"
                  autoComplete="name"
                  placeholder="이름을 입력해주세요"
                  onChange={(e) => {
                    formRegister("name").onChange(e);
                    trigger("name");
                  }}
                />
              </div>
              {errors.name && <p className="error">{errors.name.message}</p>}
            </div>
            <div className="loginInputDiv">
              <label htmlFor="username">닉네임</label>
              <div className="loginInput my-flex justify-content-center">
                <FaUserShield className="loginIcon" />
                <input
                  {...formRegister("username")}
                  type="text"
                  id="username"
                  autoComplete="username"
                  placeholder="닉네임을 입력해주세요"
                  onChange={(e) => {
                    formRegister("username").onChange(e);
                    trigger("username");
                  }}
                />
              </div>
              {errors.username && (
                <p className="error">{errors.username.message}</p>
              )}
            </div>
            <div className="loginInputDiv">
              <label htmlFor="password">비밀번호</label>
              <div className="loginInput my-flex justify-content-center">
                <BsFillShieldLockFill className="loginIcon" />
                <input
                  {...formRegister("password")}
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  placeholder="비밀번호를 입력해주세요"
                  onChange={(e) => {
                    formRegister("password").onChange(e);
                    trigger("password");
                  }}
                />
              </div>
              {errors.password && (
                <p className="error">{errors.password.message}</p>
              )}
            </div>

            <button
              type="submit"
              className="loginBtn my-flex"
              disabled={
                isSubmitting ||
                !emailValue ||
                !nameValue ||
                !userNameValue ||
                !passwordValue ||
                !verificationCodeValue
              }
            >
              <span>회원가입</span>
              <AiOutlineLogin className="loginIcon" />
            </button>

            <div className="socialLogin" style={{ marginTop: "1rem" }}>
              <span>또는 다음을 통해 가입:</span>
              <div className="socialLoginButton">
                <motion.button
                  whileHover={{ scale: 1.3 }}
                  onClick={handleGoogleLogin}
                  type="button"
                >
                  <FcGoogle className="myicon mx-2" />
                </motion.button>
                <motion.button whileHover={{ scale: 1.3 }} type="button">
                  <div id="naverIdLogin"></div>
                </motion.button>
              </div>
              <span
                onClick={props.toggleForm}
                className="forgotPassword showInSmall"
              >
                계정이 이미 있으신가요?
                <Link to={"/login"}> 로그인</Link>
              </span>
            </div>
          </form>
        </div>
      </div>
      <GoToTop />
    </div>
  );
};

export default RegisterForm;
// export default transition(RegisterForm);
