import { motion, useAnimation } from "framer-motion";
import React, { useState } from "react";
import { FaAngleDoubleUp, FaCheck } from "react-icons/fa";
import "./UploadButton.css";

// Animation variants for the icon
const iconVariants = {
  hovered: {
    y: [0, -4, 0, 2, 0],
    transition: { duration: 1, ease: "easeInOut", repeat: Infinity },
  },
};

const UploadButton = (props) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const uploadControls = useAnimation();
  const loadingControls = useAnimation();
  const doneControls = useAnimation();
  const loaderControls = useAnimation();
  const loadingBarControls = useAnimation();

  const animate = async () => {
    setIsAnimating(true);

    // Start the upload animation
    uploadControls.start({
      zIndex: 1,
    });

    // Loading animation
    await loadingControls.start({
      top: 0,
      transition: { duration: 0.3 },
    });

    // Simulate the loading process with a loading bar
    loadingBarControls.start({
      width: "100%",
      transition: { duration: 1.35 },
    });

    // Hide the upload container
    uploadControls.start({
      top: "-100%",
      transition: { duration: 0 },
    });

    // Show the done animation
    await doneControls.start({
      top: 0,
      transition: { delay: 1.5, duration: 0.3 },
    });

    // Reset the loading controls
    loadingControls.start({
      top: "-100%",
      transition: { duration: 0 },
    });
    loadingBarControls.start({
      width: "0%",
    });

    // Bring back the upload icon and text
    await uploadControls.start({
      top: 0,
      zIndex: 4,
      transition: { delay: 2, duration: 0.3 },
    });

    // Reset the done animation
    doneControls.start({
      top: "-100%",
      transition: { duration: 0 },
    });

    setIsAnimating(false);

    if (props.onComplete) {
      props.onComplete();
    }
  };

  return (
    <div className="upload-button">
      <div className="wrapper" onClick={() => !isAnimating && animate()}>
        <motion.div
          className="container upload"
          animate={uploadControls}
          whileHover="hovered"
        >
          <motion.div variants={iconVariants}>
            <FaAngleDoubleUp />
          </motion.div>
          <div>작성</div>
        </motion.div>
        <motion.div className="container loading" animate={loadingControls}>
          <motion.div className="upload-loader" animate={loaderControls} />
          <div>로딩</div>
          <motion.div className="loading-bar" animate={loadingBarControls} />
        </motion.div>
        <motion.div className="container done" animate={doneControls}>
          <FaCheck />
          <div>완료</div>
        </motion.div>
      </div>
    </div>
  );
};

export default UploadButton;
