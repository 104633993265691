import backImg from "../imgs/cards/back.png";

export const TarotBack = "back.png";

export const TarotDeck = [
  "major 0.jpg",
  "major 1.jpg",
  "major 2.jpg",
  "major 3.jpg",
  "major 4.jpg",
  "major 5.jpg",
  "major 6.jpg",
  "major 7.jpg",
  "major 8.jpg",
  "major 9.jpg",
  "major 10.jpg",
  "major 11.jpg",
  "major 12.jpg",
  "major 13.jpg",
  "major 14.jpg",
  "major 15.jpg",
  "major 16.jpg",
  "major 17.jpg",
  "major 18.jpg",
  "major 19.jpg",
  "major 20.jpg",
  "major 21.jpg",
  "cups 1.jpg",
  "cups 2.jpg",
  "cups 3.jpg",
  "cups 4.jpg",
  "cups 5.jpg",
  "cups 6.jpg",
  "cups 7.jpg",
  "cups 8.jpg",
  "cups 9.jpg",
  "cups 10.jpg",
  "cups page.jpg",
  "cups knight.jpg",
  "cups queen.jpg",
  "cups king.jpg",
  "swords 1.jpg",
  "swords 2.jpg",
  "swords 3.jpg",
  "swords 4.jpg",
  "swords 5.jpg",
  "swords 6.png",
  "swords 7.jpg",
  "swords 8.jpg",
  "swords 9.jpg",
  "swords 10.jpg",
  "swords page.jpg",
  "swords knight.jpg",
  "swords queen.jpg",
  "swords king.jpg",
  "pentacles 1.jpg",
  "pentacles 2.jpg",
  "pentacles 3.jpg",
  "pentacles 4.jpg",
  "pentacles 5.jpg",
  "pentacles 6.jpg",
  "pentacles 7.jpg",
  "pentacles 8.jpg",
  "pentacles 9.jpg",
  "pentacles 10.jpg",
  "pentacles page.jpg",
  "pentacles knight.jpg",
  "pentacles queen.jpg",
  "pentacles king.jpg",
  "wands 1.jpg",
  "wands 2.jpg",
  "wands 3.jpg",
  "wands 4.jpg",
  "wands 5.jpg",
  "wands 6.jpg",
  "wands 7.jpg",
  "wands 8.jpg",
  "wands 9.jpg",
  "wands 10.jpg",
  "wands page.jpg",
  "wands knight.jpg",
  "wands queen.jpg",
  "wands king.jpg",
];

export const Cards = [
  {
    imageSrc: backImg,
    title: "major 0.jpg",
  },
  {
    imageSrc: backImg,
    title: "major 1.jpg",
  },
  {
    imageSrc: backImg,
    title: "major 2.jpg",
  },
  {
    imageSrc: backImg,
    title: "major 3.jpg",
  },
  {
    imageSrc: backImg,
    title: "major 4.jpg",
  },
  {
    imageSrc: backImg,
    title: "major 5.jpg",
  },
  {
    imageSrc: backImg,
    title: "major 6.jpg",
  },
  {
    imageSrc: backImg,
    title: "major 7.jpg",
  },
  {
    imageSrc: backImg,
    title: "major 8.jpg",
  },
  {
    imageSrc: backImg,
    title: "major 9.jpg",
  },
  {
    imageSrc: backImg,
    title: "major 10.jpg",
  },
  {
    imageSrc: backImg,
    title: "major 11.jpg",
  },
  {
    imageSrc: backImg,
    title: "major 12.jpg",
  },
  {
    imageSrc: backImg,
    title: "major 13.jpg",
  },
  {
    imageSrc: backImg,
    title: "major 14.jpg",
  },
  {
    imageSrc: backImg,
    title: "major 15.jpg",
  },
  {
    imageSrc: backImg,
    title: "major 16.jpg",
  },
  {
    imageSrc: backImg,
    title: "major 17.jpg",
  },
  {
    imageSrc: backImg,
    title: "major 18.jpg",
  },
  {
    imageSrc: backImg,
    title: "major 19.jpg",
  },
  {
    imageSrc: backImg,
    title: "major 20.jpg",
  },
  {
    imageSrc: backImg,
    title: "major 21.jpg",
  },
  {
    imageSrc: backImg,
    title: "cups 1.jpg",
  },
  {
    imageSrc: backImg,
    title: "cups 2.jpg",
  },
  {
    imageSrc: backImg,
    title: "cups 3.jpg",
  },
  {
    imageSrc: backImg,
    title: "cups 4.jpg",
  },
  {
    imageSrc: backImg,
    title: "cups 5.jpg",
  },
  {
    imageSrc: backImg,
    title: "cups 6.jpg",
  },
  {
    imageSrc: backImg,
    title: "cups 7.jpg",
  },
  {
    imageSrc: backImg,
    title: "cups 8.jpg",
  },
  {
    imageSrc: backImg,
    title: "cups 9.jpg",
  },
  {
    imageSrc: backImg,
    title: "cups 10.jpg",
  },
  {
    imageSrc: backImg,
    title: "cups page.jpg",
  },
  {
    imageSrc: backImg,
    title: "cups knight.jpg",
  },
  {
    imageSrc: backImg,
    title: "cups queen.jpg",
  },
  {
    imageSrc: backImg,
    title: "cups king.jpg",
  },
  {
    imageSrc: backImg,
    title: "swords 1.jpg",
  },
  {
    imageSrc: backImg,
    title: "swords 2.jpg",
  },
  {
    imageSrc: backImg,
    title: "swords 3.jpg",
  },
  {
    imageSrc: backImg,
    title: "swords 4.jpg",
  },
  {
    imageSrc: backImg,
    title: "swords 5.jpg",
  },
  {
    imageSrc: backImg,
    title: "swords 6.png",
  },
  {
    imageSrc: backImg,
    title: "swords 7.jpg",
  },
  {
    imageSrc: backImg,
    title: "swords 8.jpg",
  },
  {
    imageSrc: backImg,
    title: "swords 9.jpg",
  },
  {
    imageSrc: backImg,
    title: "swords 10.jpg",
  },
  {
    imageSrc: backImg,
    title: "swords page.jpg",
  },
  {
    imageSrc: backImg,
    title: "swords knight.jpg",
  },
  {
    imageSrc: backImg,
    title: "swords queen.jpg",
  },
  {
    imageSrc: backImg,
    title: "swords king.jpg",
  },
  {
    imageSrc: backImg,
    title: "pentacles 1.jpg",
  },
  {
    imageSrc: backImg,
    title: "pentacles 2.jpg",
  },
  {
    imageSrc: backImg,
    title: "wands 1.jpg",
  },
  {
    imageSrc: backImg,
    title: "wands 2.jpg",
  },

  {
    imageSrc: backImg,
    title: "pentacles 1.jpg",
  },
  {
    imageSrc: backImg,
    title: "pentacles 2.jpg",
  },
  {
    imageSrc: backImg,
    title: "pentacles 3.jpg",
  },
  {
    imageSrc: backImg,
    title: "pentacles 4.jpg",
  },
  {
    imageSrc: backImg,
    title: "pentacles 5.jpg",
  },
  {
    imageSrc: backImg,
    title: "pentacles 6.jpg",
  },
  {
    imageSrc: backImg,
    title: "pentacles 7.jpg",
  },
  {
    imageSrc: backImg,
    title: "pentacles 8.jpg",
  },
  {
    imageSrc: backImg,
    title: "pentacles 9.jpg",
  },
  {
    imageSrc: backImg,
    title: "pentacles 10.jpg",
  },
  {
    imageSrc: backImg,
    title: "pentacles page.jpg",
  },
  {
    imageSrc: backImg,
    title: "pentacles knight.jpg",
  },
  {
    imageSrc: backImg,
    title: "pentacles queen.jpg",
  },
  {
    imageSrc: backImg,
    title: "pentacles king.jpg",
  },

  {
    imageSrc: backImg,
    title: "wands 1.jpg",
  },
  {
    imageSrc: backImg,
    title: "wands 2.jpg",
  },
  {
    imageSrc: backImg,
    title: "wands 3.jpg",
  },
  {
    imageSrc: backImg,
    title: "wands 4.jpg",
  },
  {
    imageSrc: backImg,
    title: "wands 5.jpg",
  },
  {
    imageSrc: backImg,
    title: "wands 6.jpg",
  },
  {
    imageSrc: backImg,
    title: "wands 7.jpg",
  },
  {
    imageSrc: backImg,
    title: "wands 8.jpg",
  },
  {
    imageSrc: backImg,
    title: "wands 9.jpg",
  },
  {
    imageSrc: backImg,
    title: "wands 10.jpg",
  },
  {
    imageSrc: backImg,
    title: "wands page.jpg",
  },
  {
    imageSrc: backImg,
    title: "wands knight.jpg",
  },
  {
    imageSrc: backImg,
    title: "wands queen.jpg",
  },
  {
    imageSrc: backImg,
    title: "wands king.jpg",
  },
];

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export const smallerCards = shuffleArray([...Cards]).slice(0, 20);

export const smallestCards = shuffleArray([...Cards]).slice(0, 7);