import React, { useEffect, useState } from "react";
import GoToTop from "../components/GoToTop";
import Header from "../components/Header";
import QuestionIcon from "../components/QuestionIcon";
import RevealButton from "../components/RevealButton";
import Spreadable from "../components/Spreadable";
import SpreadableSM from "../components/SpreadableSM";
import SpreadableXS from "../components/SpreadableXS";
import { useLocation, useNavigate } from "react-router-dom";

const Money = () => {
  const navigate = useNavigate();
  const [selectedCard, setSelectedCard] = useState("");
  const handleCardSelected = (title) => {
    setSelectedCard(title);
  };
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isSmallestScreen, setIsSmallestScreen] = useState(false);
  const location = useLocation();
  
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setIsSmallScreen(screenWidth < 1024);
      setIsSmallestScreen(screenWidth < 692);
    };

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div style={{ paddingBottom: "150px" }}>
      <Header
        title="금전 운세"
        subtitle="생각을 비우고 편안한 마음으로 카드 한 장을 선택해주세요."
        text={`
          금전적인 성공과 안정은 우리 삶에서 매우 중요한 부분입니다. 
          타로 카드를 통해 금전 운세를 확인함으로써, 재정적인 기회와 도전에 대한 새로운 관점을 얻을 수 있습니다. 
        `}
      />
      {/* <QuestionIcon placement="right" /> */}
      {isSmallestScreen ? (
        <SpreadableXS
          onCardSelect={handleCardSelected}
          navigate={navigate}
          tarotType="money"
          from={location.pathname}
        />
      ) : isSmallScreen ? (
        <SpreadableSM
          onCardSelect={handleCardSelected}
          navigate={navigate}
          tarotType="money"
          from={location.pathname}
        />
      ) : (
        <Spreadable
          onCardSelect={handleCardSelected}
          navigate={navigate}
          tarotType="money"
          from={location.pathname}
        />
      )}
      {/* <div
        style={{
          position: "absolute",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <RevealButton s1="open" />
      </div> */}
      <GoToTop />
    </div>
  );
};

export default Money;
