import { IMAGES_URL } from "../constants";
import { apiSlice } from "./apiSlice";

export const imageApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    deleteImage: builder.mutation({
      query: (imageName) => ({
        url: `${IMAGES_URL}/${imageName}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const { useDeleteImageMutation } = imageApiSlice;
