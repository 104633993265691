import React, { Component, Fragment } from "react";
import MyBarajaJS from "react-baraja-js-customized";
import { Cards } from "../data/constants";
import "./Spreadable.css";
import BarajaJS from "react-baraja-js";
import { Navigate } from "react-router-dom";

class Spreadable extends Component {
  constructor() {
    super();
    this.state = {
      fan: {},
      navigateToCard: false, // New state property
      selectedCard: null,
    };
  }

  fanEvent() {
    this.setState({
      fan: {
        direction: "right",
        easing: "ease-out",
        origin: {
          x: 50,
          y: 200,
        },
        speed: 500,
        range: 100,
        center: true,
      },
    });
  }

  componentDidMount() {
    this.autoClickFunction();
  }

  autoClickFunction = () => {
    // Your button's onClick function logic here
    this.fanEvent();
  };


  shuffleArray = (arr) => {
    let array = arr.slice(); // Create a shallow copy to avoid modifying the original array
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  handleClick = (title) => {
    // Update the navigate state along with the selected card
    // this.setState({
    //   navigateToCard: true,
    //   selectedCard: title,
    // });
    // console.log("Card clicked:", title);
    this.props.navigate("/selected-card", { state: { userCard: title, tarotType: this.props.tarotType, from: this.props.from } })

  };

  handleParentClick = (event) => {
    let targetElement = event.target;

    // If the clicked element is the img itself or any of its descendants
    if (targetElement.tagName !== 'IMG') {
        targetElement = targetElement.closest('li').querySelector('img');
    }

    if (targetElement && targetElement.getAttribute('data-title')) {
        const title = targetElement.getAttribute('data-title');
        // console.log('Extracted title:', title);
        this.handleClick(title);
    } else {
        console.log("Title not found on clicked element.");
    }
};
  render() {
        const { fan, navigateToCard, selectedCard } = this.state;
        const shuffledCards = this.shuffleArray(Cards);

    // if (navigateToCard) {
    //   // Navigate when navigateToCard is true
    //   return (
    //     <Navigate
    //       to="/selected-card"
    //       replace={true}
    //       state={{ userCard: selectedCard }}
    //     />
    //   );
    // }
    return (
      <main className="card-box" onClick={this.handleParentClick}>
        <Fragment>
          <BarajaJS fan={fan} onClick={() => this.fanEvent()}>
            {shuffledCards.map(({ imageSrc, title, details }, index) => {
              return (
                <Fragment key={index}>
                  <img
                    alt={title}
                    src={imageSrc}
                    style={{ width: "220px", height: "310px" }}
                    data-title={title}
                    // onClick={() => this.handleClick(title)}
                  />
                </Fragment>
              );
            })}
          </BarajaJS>
          <button
            style={{ display: "none" }}
            id="fan-button"
            onClick={this.autoClickFunction}
          >
            Fan right
          </button>
        </Fragment>
      </main>
    );
  }
}

export default Spreadable;
