import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineLogin } from "react-icons/ai";
import { BsFillShieldLockFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import GoToTop from "../components/GoToTop";
import useCountdown from "../hooks/useCountdown";
import cards from "../imgs/star2.mp4";
import {
  useResetPasswordCodeMutation,
  useResetPasswordMutation,
} from "../slices/usersApiSlice";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("이메일 주소를 입력해주세요")
    .required("이메일을 입력해주세요"),
  password: yup
    .string()
    .min(8, "비밀번호는 최소 8자리를 입력해주세요")
    .matches(/[a-z]+/, "비밀번호는 최소 영문 소문자 한자리를 입력해주세요")
    .matches(/[A-Z]+/, "비밀번호는 최소 영문 대문자 한자리를 입력해주세요")
    .matches(/[@$!%*#?&]+/, "비밀번호는 최소 특수문자 한자리를 입력해주세요")
    .matches(/\d+/, "비밀번호는 최소 숫자 한자리를 입력해주세요")
    .required("비밀번호를 입력해주세요"),
  verificationCode: yup.string().required("인증 번호를 입력해주세요"),
});

const PasswordForm = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isMdScreen, setIsMdScreen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 550);
      setIsMdScreen(window.innerWidth < 1024);
    };

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const {
    register: formRegister,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
    trigger,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const emailValue = watch("email");

  const [verificationCodeRequestPending, setVerificationCodeRequestPending] =
    useState(false);
  const [showVerificationCodeSentText, setShowVerificationCodeSentText] =
    useState(false);
  const {
    secondsLeft: verificationCodeCooldownSecondsLeft,
    start: startVerificationCodeCooldown,
  } = useCountdown();

  const [verificationCode, { isLoading: loadingCode }] =
    useResetPasswordCodeMutation();

  const [resetPassword, { isLoading: loadingUpdate }] =
    useResetPasswordMutation();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      setShowVerificationCodeSentText(false);
      await resetPassword(data).unwrap();
      navigate("/login");
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const requestVerificationCode = async ({ email }) => {
    setShowVerificationCodeSentText(false);
    setVerificationCodeRequestPending(true);

    try {
      await verificationCode(email).unwrap();
      setShowVerificationCodeSentText(true);
      startVerificationCodeCooldown(180);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    } finally {
      setVerificationCodeRequestPending(false);
    }
  };

  return (
    <div className="loginPage my-flex">
      <div className="loginContainer my-flex">
        <div className="videoDiv">
          <video src={cards}  autoPlay={isSmallScreen ? false : true} muted loop></video>
          <div className="loginTextDiv">
            <h2 className="loginTitle">운명의 카드를 탐색하다</h2>
            <p className="loginText">
              오늘 당신의 타로 카드는 무엇을 말해줄까요?
            </p>
          </div>
          <div className="loginFooterDiv my-flex">
            <span className="text">비밀번호를 변경하셨나요?</span>
            <Link to={"/login"}>
              <button className="loginBtn">로그인</button>
            </Link>
          </div>
        </div>

        <div className="loginFormDiv my-flex">
          <div className="loginHeaderDiv">
            <h3>비밀번호 변경</h3>
          </div>
          {showVerificationCodeSentText && (
            <p className="error mt-1">
              이메일 인증 번호가 발송되었습니다. 이메일을 확인해주세요.
            </p>
          )}
          <form
            action=""
            className="loginForm my-grid"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="loginInputDiv">
              <label htmlFor="email">이메일</label>
              <div className="loginInput my-flex justify-content-center">
                <MdEmail className="loginIcon" />
                <input
                  {...formRegister("email")}
                  type="email"
                  id="email"
                  autoComplete="email"
                  placeholder="이메일을 입력해주세요"
                  onChange={(e) => {
                    formRegister("email").onChange(e);
                    trigger("email");
                  }}
                />
              </div>
              {errors.email && <p className="error">{errors.email.message}</p>}
            </div>
            <div className="loginInputDiv">
              <label htmlFor="verificationCode">이메일 인증 번호</label>
              <div className="loginInput my-flex justify-content-center">
                <BsFillShieldLockFill className="loginIcon" />
                <input
                  type="number"
                  id="verificationCode"
                  placeholder="인증 번호를 입력해주세요"
                  {...formRegister("verificationCode")}
                />
                <button
                  className="send-verification-btn"
                  id="button-send-verification-code"
                  disabled={
                    verificationCodeRequestPending ||
                    verificationCodeCooldownSecondsLeft > 0 ||
                    !emailValue
                  }
                  onClick={() => requestVerificationCode({ email: emailValue })}
                >
                  전송
                  {verificationCodeCooldownSecondsLeft > 0 &&
                    ` (${verificationCodeCooldownSecondsLeft})`}
                </button>
              </div>
              {errors.verificationCode && (
                <p className="error">{errors.verificationCode.message}</p>
              )}
            </div>
            <div className="loginInputDiv">
              <label htmlFor="password">새로운 비밀번호</label>
              <div className="loginInput my-flex justify-content-center">
                <BsFillShieldLockFill className="loginIcon" />
                <input
                  {...formRegister("password")}
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  placeholder="새로운 비밀번호를 입력해주세요"
                  onChange={(e) => {
                    formRegister("password").onChange(e);
                    trigger("password");
                  }}
                />
              </div>
              {errors.password && (
                <p className="error">{errors.password.message}</p>
              )}
            </div>
            <button type="submit" className="loginBtn my-flex">
              <span>변경</span>
              <AiOutlineLogin className="loginIcon" />
            </button>
            <span className="forgotPassword foundAccount showInSmall">
              비밀번호를 찾으셨습니까?
              <Link to={"/login"}> 로그인</Link>
            </span>
          </form>
        </div>
      </div>
      <GoToTop />
    </div>
  );
};

export default PasswordForm;
