import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetBlogPostsQuery } from "../slices/blogPostsApiSlice";
import Blog from "./Blog";
import Loader from "./Loader";
import Message from "./Message";
import "./UnderlinedMenu.css";

const menuItems = [
  "모든 카테고리",
  "타로 카드",
  "타로 리딩",
  "타로 역사",
  "그외",
];

const MenuItem = ({ text, selected, onClick }) => (
  <motion.div
    className="menu-item"
    onClick={onClick}
    animate={{ opacity: selected ? 1 : 0.5 }}
    layout
  >
    {text}
    {selected && <motion.div className="underline" layoutId="underline" />}
  </motion.div>
);

const UnderlinedMenu = () => {
  const [selected, setSelected] = useState(0);
  const [filteredPosts, setFilteredPosts] = useState([]);

  const { keyword } = useParams();
  const {
    data: blogPosts,
    isLoading,
    error,
  } = useGetBlogPostsQuery({ keyword });

  useEffect(() => {
    if (blogPosts) {
      filterPosts(menuItems[selected]);
    }
  }, [blogPosts, selected]);

  const filterPosts = (category) => {
    let filtered = blogPosts;
    if (category !== "모든 카테고리") {
      filtered = blogPosts.filter((post) => post.category === category);
    }
    setFilteredPosts(filtered);
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message />
      ) : (
        <>
          <div className="underlined-menu ps-1 py-4 mb-4">
            <div className="wrapper py-2">
              {menuItems.map((item, index) => (
                <MenuItem
                  key={index}
                  text={item}
                  selected={selected === index}
                  onClick={() => {
                    setSelected(index);
                    filterPosts(item);
                  }}
                />
              ))}
            </div>
          </div>
          <div className="posts">
            {filteredPosts.length > 0 ? (
              <Blog posts={filteredPosts} />
            ) : (
              <p className="post-date" style={{ marginBottom: "6rem" }}>
                아직 포스트가 없습니다
              </p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default UnderlinedMenu;
