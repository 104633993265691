import React from "react";
import {
  Button,
  Card,
  Col,
  Nav,
  Row,
  Tab,
  Tabs
} from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useDeleteCommentMutation,
  useGetCommentsProfileQuery,
} from "../slices/commentsApiSlice";
import {
  useDeleteForumPostMutation,
  useGetForumPostsProfileQuery,
} from "../slices/forumPostsApiSlice";
import { useDeleteImageMutation } from "../slices/imageApiSlice";
import Loader from "./Loader";
import Message from "./Message";
import Paginate from "./Paginate";
import "./TabsComponent.css";
import { extractImageUrls } from "./extractImageUrls";

const TabsComponent = () => {
  const { pageNumber } = useParams();
  const { data, isLoading, error, refetch } = useGetForumPostsProfileQuery({
    pageNumber,
  });
  const {
    data: commentData,
    isLoading: loadingComment,
    refetch: refetchComment,
  } = useGetCommentsProfileQuery({ pageNumber });

  const [deleteForumPost, { isLoading: loadingDelete }] =
    useDeleteForumPostMutation();

  const [deleteComment] = useDeleteCommentMutation();
  const [deleteImage] = useDeleteImageMutation();
  const { userInfo } = useSelector((state) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();

  const determineKeyFromPath = () => {
    if (location.pathname.includes("comment")) {
      return "myComment";
    }
    return "myPost";
  };

  const key = determineKeyFromPath();

  const handleSelect = (k) => {
    navigate(
      k === "myPost" ? "/profile/tarot-forum/page/1" : "/profile/comment/page/1"
    );
  };

  const deleteHandler = async (id, content) => {
    if (window.confirm("해당 글을 삭제하시겠습니까?")) {
      try {
        await deleteForumPost(id);
        const imageUrls = extractImageUrls(content);
        for (const imageUrl of imageUrls) {
          const filename = imageUrl.split("/").pop();
          try {
            await deleteImage(filename).unwrap();
          } catch (err) {
            toast.error(err?.data?.message || "Failed to delete image");
          }
        }
        refetch();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };
  const deleteHandlerComment = async (commentId) => {
    if (window.confirm("해당 댓글을 삭제하시겠습니까?")) {
      try {
        await deleteComment(commentId);
        refetchComment();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  const formatDate = (date) => {
    const postDate = new Date(date);
    return postDate.toLocaleDateString("ko-KR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Tabs
          id="user-tabs"
          activeKey={key}
          onSelect={handleSelect}
          variant="underline"
          className="profile-tabs"
        >
          <Tab eventKey="myPost" title="전체 글">
            <Card className="my-3" style={{ backgroundColor: "transparent" }}>
              <Card.Body className="table-title-container">
                <Row>
                  <Col>
                    <Card.Text>제목</Card.Text>
                  </Col>
                  <Col>
                    <Card.Text>날짜</Card.Text>
                  </Col>
                  <Col>수정</Col>
                </Row>
              </Card.Body>
            </Card>
            {data?.forumPosts?.map((forumPost) => (
              <Card key={forumPost._id} className="profile-post-container mb-3">
                <Card.Body>
                  <Row>
                    <Col>
                      <Card.Text>{forumPost.title}</Card.Text>
                    </Col>
                    <Col>
                      <Card.Text>{formatDate(forumPost.createdAt)}</Card.Text>
                    </Col>
                    <Col className="profile-post-edit">
                      <Nav.Link
                        as={Link}
                        to={`/forum-post/${forumPost._id}/edit`}
                        className="mx-2 align-self-center"
                        style={{ backgroundColor: "#f8f9fa", borderRadius: "0.25rem"}}
                      >
                        <Button variant="light" className="btn-sm">
                          <FaEdit />
                        </Button>
                      </Nav.Link>
                      <Button
                        variant="danger"
                        className="btn-sm align-self-center"
                        onClick={() =>
                          deleteHandler(forumPost._id, forumPost.content.ops)
                        }
                      >
                        <FaTrash style={{ color: "white" }} />
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            ))}
            <Paginate
              pages={data?.pages}
              page={data?.page}
              curLocation="profile/tarot-forum"
            />
          </Tab>
          <Tab eventKey="myComment" title="전체 댓글">
            <Card className="my-3" style={{ backgroundColor: "transparent" }}>
              <Card.Body style={{ padding: 0, color: "#fff" }}>
                <Row>
                  <Col>
                    <Card.Text>내용</Card.Text>
                  </Col>
                  <Col>
                    <Card.Text>날짜</Card.Text>
                  </Col>
                  <Col>수정</Col>
                </Row>
              </Card.Body>
            </Card>
            {commentData?.comments?.map((comment) => (
              <Card key={comment._id} className="profile-post-container mb-3">
                <Card.Body>
                  <Row>
                    <Col>
                      <Card.Text>{comment.content}</Card.Text>
                    </Col>
                    <Col>
                      <Card.Text>{formatDate(comment.createdAt)}</Card.Text>
                    </Col>
                    <Col className="profile-post-edit">
                      {comment.postType === "ForumPost" ? (
                        <Nav.Link
                          as={Link}
                          to={`/tarot-forum/post/${comment.postId}`}
                        >
                          <Button variant="light" className="btn-sm mx-2">
                            <FaEdit />
                          </Button>
                        </Nav.Link>
                      ) : (
                        <Nav.Link
                          as={Link}
                          to={`/tarot-master/post/${comment.postId}`}
                        >
                          <Button variant="light" className="btn-sm mx-2">
                            <FaEdit />
                          </Button>
                        </Nav.Link>
                      )}

                      <Button
                        variant="danger"
                        className="btn-sm"
                        onClick={() => deleteHandlerComment(comment._id)}
                      >
                        <FaTrash style={{ color: "white" }} />
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            ))}
            <Paginate
              pages={commentData?.pages}
              page={commentData?.page}
              curLocation="profile/comment"
            />
          </Tab>
        </Tabs>
      )}
    </>
  );
};

export default TabsComponent;
