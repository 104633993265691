import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./PostRenderer.css";

const PostRenderer = ({ post }) => {
  const deltaToPlainText = (deltaObj) => {
    if (!deltaObj || !deltaObj.ops) return "";

    return deltaObj.ops
      .map((op) => {
        if (typeof op.insert === "string") {
          return op.insert;
        }
        // If the insert is not a string (e.g., an embed like an image), replace it with a space or some placeholder text
        return " ";
      })
      .join("");
  };

  // Function to truncate text to the first 200 words
  const truncateText = (text, maxWords) => {
    const words = text.split(/\s+/); // Split by any whitespace
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(" ") + "..."; // Add ellipsis to indicate truncation
    }
    return text;
  };
  const plainText = deltaToPlainText(post.content);
  const truncatedText = truncateText(plainText, 50);

  const formatDate = (date) => {
    const postDate = new Date(date);

    return postDate.toLocaleDateString("ko-KR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <Container className="pt-5 px-5 mb-3 info-container">
      <Row className="gx-5 align-items-center">
        <Col md="6">
          {post?.category ? (
            <span className="post-category py-1 px-2 ">타로 마스터</span>
          ) : (
            <span className="post-category py-1 px-2 ">타로 포럼</span>
          )}

          <h4 className="mt-3">
            <strong>{post.title}</strong>
          </h4>
          <div className="tip-info">
            <span>{post.user.username} ||</span>
            <span> {formatDate(post.createdAt)}</span>
          </div>

          {post?.category ? (
            <Link key={post._id} to={`/tarot-master/post/${post._id}`}>
              <button className="tipsbtn">계속 읽기</button>
            </Link>
          ) : (
            <Link key={post._id} to={`/tarot-forum/post/${post._id}`}>
              <button className="tipsbtn">계속 읽기</button>
            </Link>
          )}
        </Col>
        <Col md="6">
          <p className="info-text-muted mx-5">{truncatedText + "..."}</p>
        </Col>
      </Row>
      <hr />
    </Container>
  );
};

export default PostRenderer;
