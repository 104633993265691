import React from "react";
import "./Message.css";

const Message = () => {
  return (
    <div className="error-page">
      <h2 className="error-header" data-text="error">
        error
      </h2>
      <p className="error-text" data-text="나중에 다시 시도해주세요">
        나중에 다시 시도해주세요
      </p>
    </div>
  );
};

export default Message;
