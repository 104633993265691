import React, { useCallback, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAddForumPostMutation } from "../slices/forumPostsApiSlice";
import { useDeleteImageMutation } from "../slices/imageApiSlice";
import EditorQuill from "./EditorQuill";
import GoToTop from "./GoToTop";
import Header from "./Header";
import UploadButton from "./UploadButton";

const AddPost = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [addForumPost, { isLoading }] = useAddForumPostMutation();
  const navigate = useNavigate();
  const [deleteImage] = useDeleteImageMutation();

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleEditorChange = useCallback((newContent) => {
    setContent(newContent);
  }, []);

  const [imageUrls, setImageUrls] = useState(new Set()); // State to store image URLs from EditorQuill

  const handleImageUrlsChange = useCallback((newImageUrls) => {
    setImageUrls(newImageUrls);
  }, []);

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    for (const imageUrl of imageUrls) {
      const filename = imageUrl.split("/").pop();
      try {
        await deleteImage(filename).unwrap();
      } catch (err) {
        toast.error(err?.data?.message || "Failed to delete image");
      }
    }
    try {
      await addForumPost({ title, content }).unwrap();
      toast.success("성공적으로 작성되었습니다");
      navigate("/tarot-forum");
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <div className="mx-auto px-5">
      <Header
        title="새로운 글 작성"
        text={`오늘은 어떤 내용에 대해 작성하실건가요?`}
      />
      <Container>
        <Form className="new-blog-container">
          <Form.Group controlId="formTitle" className="py-2">
            <Form.Label>제목</Form.Label>
            <Form.Control
              type="text"
              placeholder="제목을 입력해주세요"
              value={title}
              onChange={handleTitleChange}
            />
          </Form.Group>

          <EditorQuill
            placeholder="오늘은 무엇에 대해 쓰실건가요?"
            onContentChange={handleEditorChange}
            onImageUrlsChange={handleImageUrlsChange}
          />
          <div className="d-flex justify-content-center align-items-center">
            <UploadButton onComplete={handleSubmit} link="/tarot-forum" />
          </div>
        </Form>
      </Container>
      <GoToTop />
    </div>
  );
};

export default AddPost;
