import { apiSlice } from "./apiSlice";
import { USERS_URL } from "../constants";

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/login`,
        method: "POST",
        body: data,
      }),
    }),
    register: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}`,
        method: "POST",
        body: data,
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: `${USERS_URL}/logout`,
        method: "POST",
      }),
    }),
    profile: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/profile`,
        method: "PUT",
        body: data,
      }),
    }),
    getProfile: builder.query({
      query: () => ({
        url: `${USERS_URL}/profile`,
      }),
      keepUnusedDataFor: 5,
    }),
    getUsers: builder.query({
      query: () => ({
        url: USERS_URL,
      }),
      providesTags: ["User"],
      keepUnusedDataFor: 5,
    }),
    deleteUser: builder.mutation({
      query: (userId) => ({
        url: `${USERS_URL}/${userId}`,
        method: "DELETE",
      }),
    }),
    getUserDetails: builder.query({
      query: (id) => ({
        url: `${USERS_URL}/${id}`,
      }),
      keepUnusedDataFor: 5,
    }),
    updateUser: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/${data.userId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["User"],
    }),
    uploadUserImage: builder.mutation({
      query: (data) => ({
        url: "/api/upload",
        method: "POST",
        body: data,
      }),
    }),
    sendVerificationCode: builder.mutation({
      query: (email) => ({
        url: `${USERS_URL}/verification-code`,
        method: "POST",
        body: { email },
      }),
    }),
    sendContact: builder.mutation({
      query: ({ name, email, content }) => ({
        url: `${USERS_URL}/contact`,
        method: "POST",
        body: { name, email, content },
      }),
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/reset-password`,
        method: "POST",
        body: data,
      }),
    }),
    resetPasswordCode: builder.mutation({
      query: (email) => ({
        url: `${USERS_URL}/reset-password-code`,
        method: "POST",
        body: { email },
      }),
    }),
    kakaoLogin: builder.mutation({
      query: (accessToken) => ({
        url: `${USERS_URL}/kakao-login`,
        method: "POST",
        body: { accessToken },
      }),
    }),
    googleLogin: builder.mutation({
      query: (accessToken) => ({
        url: `${USERS_URL}/google-login`,
        method: "POST",
        body: { accessToken },
      }),
    }),
    naverLogin: builder.mutation({
      query: (accessToken) => ({
        url: `${USERS_URL}/naver-login`,
        method: "POST",
        body: { accessToken },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useLogoutMutation,
  useProfileMutation,
  useGetProfileQuery,
  useGetUsersQuery,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useGetUserDetailsQuery,
  useUploadUserImageMutation,
  useSendVerificationCodeMutation,
  useResetPasswordMutation,
  useResetPasswordCodeMutation,
  useKakaoLoginMutation,
  useGoogleLoginMutation,
  useNaverLoginMutation,
  useSendContactMutation
} = usersApiSlice;
