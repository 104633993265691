import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import GoToTop from "../components/GoToTop";
import TabsComponent from "../components/TabsComponent";
import UserProfile from "../components/UserProfile";
import UserProfileMini from "../components/UserProfileMini";

const UserProfilePage = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 767);
    };

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <Container style={{ marginTop: "11rem" }}>
        {isSmallScreen ? (
          <>
          <Row className="justify-content-center gx-5 mb-3">
          <Col xs={10} md={5}>
            <div
            className="user-profile-page-first-row"
              // style={{
              //   backgroundColor: "linen",
              //   borderRadius: "20px",
              //   padding: "2rem",
              //   marginTop: "1rem",
              // }}
            >
              <UserProfileMini user={userInfo} />
            </div>
            <div
            className="user-profile-page-second-row"
              // style={{
              //   backgroundColor: "linen",
              //   borderRadius: "20px",
              //   padding: "2rem",
              //   marginTop: "2rem",
              //   marginBottom: "3rem",
              // }}
            >
              <Row>
                <UserProfile user={userInfo} />
              </Row>
            </div>
          </Col>
          {/* <Col md={7} className="mb-5">
            <TabsComponent />
          </Col> */}
        </Row>
        <Row className="justify-content-center gx-5">
          <Col xs={10} md={5}>
            <TabsComponent />
          </Col>
        </Row>
        </>
        ) : (
          <>
          <Row className="gx-5">
          <Col md={5} lg={4}>
            <div
            className="user-profile-page-first-row"
              // style={{
              //   backgroundColor: "#fdfaf4",
              //   borderRadius: "20px",
              //   padding: "2rem",
              //   marginTop: "1rem",
              // }}
            >
              <UserProfileMini user={userInfo} />
            </div>
            <div
              // style={{
              //   backgroundColor: "#fdfaf4",
              //   borderRadius: "20px",
              //   padding: "2rem",
              //   marginTop: "2rem",
              //   marginBottom: "3rem",
              // }}
              className="user-profile-page-second-row"
            >
              <Row>
                <UserProfile user={userInfo} />
              </Row>
            </div>
          </Col>
          <Col md={7} lg={8} className="mb-5">
            <TabsComponent />
          </Col>
        </Row>
        </>
        )}
        
      </Container>
      <GoToTop />
    </div>
  );
};

export default UserProfilePage;
