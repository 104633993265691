import React, { useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  useGetProfileQuery
} from "../slices/usersApiSlice";
import ChangeEmailForm from "./ChangeEmailForm";
import ChangePasswordForm from "./ChangePasswordForm";
import ChangeUsernameForm from "./ChangeUsernameForm";
import "./UserProfileMini.css";

const UserProfileMini = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const { data: user } = useGetProfileQuery(userInfo._id);

  const [show, setShow] = useState(false);
  const [showUser, setShowUser] = useState(false);
  const [showEmail, setShowEmail] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleUserClose = () => setShowUser(false);
  const handleUserShow = () => setShowUser(true);
  const handleEmailClose = () => setShowEmail(false);
  const handleEmailShow = () => setShowEmail(true);

  return (
    <div>
      <Container className="user-profile-mini-container">
        <Row style={{}}>
          <h5>안녕하세요, {user?.name}님</h5>
        </Row>
        <Row style={{}}>
          <h6>닉네임</h6>
          {user?.username ? <p>{user?.username}</p> : <p>-</p>}
        </Row>
        <Row style={{}}>
          <h6>이메일</h6>
          <p> {user?.email}</p>
        </Row>
        <Row>
          <Col>
            <button className="selected-button" onClick={handleUserShow}>
              닉네임 변경
            </button>
          </Col>
          <Modal show={showUser} onHide={handleUserClose} centered>
            <Modal.Header closeButton>
              <Modal.Title style={{ fontFamily: "Noto Serif KR" }}>
                닉네임 변경
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ChangeUsernameForm onClose={handleUserClose} />
            </Modal.Body>
          </Modal>
          {user?.isSocialLogin ? (
            <></>
          ) : (
            <>
              <Col>
                <button className="selected-button" onClick={handleEmailShow}>
                  이메일 변경
                </button>
              </Col>
              <Modal show={showEmail} onHide={handleEmailClose} centered>
                <Modal.Header closeButton>
                  <Modal.Title style={{ fontFamily: "Noto Serif KR" }}>
                    이메일 변경
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <ChangeEmailForm onClose={handleEmailClose} />
                </Modal.Body>
              </Modal>

              <Col>
                <button className="selected-button" onClick={handleShow}>
                  비밀번호 변경
                </button>
              </Col>
              <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                  <Modal.Title style={{ fontFamily: "Noto Serif KR" }}>
                    비밀번호 변경
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <ChangePasswordForm onClose={handleClose} />
                </Modal.Body>
              </Modal>
            </>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default UserProfileMini;
