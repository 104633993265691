import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ReactCardFlip from "react-card-flip";
import { useLocation, useNavigate } from "react-router-dom";
import GoToTop from "../components/GoToTop";
import RevealButton from "../components/RevealButton";
import "../components/SelectedCard.css";
import { TarotBack } from "../data/constants";
import TarotDataBirthday from "../data/tarot-kor-birthday.json";
import TarotData from "../data/tarot-kor-daily.json";
import TarotDataLover from "../data/tarot-kor-lover.json";
import TarotDataMoney from "../data/tarot-kor-mon.json"

const SelectedCard = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  let matchingCard;
  const { userCard, tarotType, from } = location.state || {};
  const [isFlipped, setIsFlipped] = useState(false);
  if (tarotType === "birthday") {
    matchingCard = TarotDataBirthday.cards.find(
      (card) => card.img === userCard
    );
  } else if (tarotType === "lover") {
    matchingCard = TarotDataLover.cards.find((card) => card.img === userCard);
  } else if (tarotType === "money") {
    matchingCard = TarotDataMoney.cards.find((card) => card.img === userCard);
  } else {
    matchingCard = TarotData.cards.find((card) => card.img === userCard);
  }

  useEffect(() => {
    setTimeout(() => {
      setIsFlipped(true);
    }, 1000);
  }, []);

  return (
    <div className="hero-sm">
      <div className="selected-title">
        <h1>선택하신 카드</h1>
      </div>
      <Container className="selected-card-page">
        <Row className="border-box">
          <Col xs={12} md={12} xl={5}>
            <ReactCardFlip isFlipped={isFlipped}>
              <div className="selected-card-box">
                <img
                  src={require(`../imgs/cards/${TarotBack}`)}
                  alt="tarot-back"
                  className="card"
                />
              </div>
              <div className="selected-card-box">
                <img
                  src={require(`../imgs/cards/${userCard}`)}
                  alt="selected-card"
                  className="card"
                />
              </div>
            </ReactCardFlip>
          </Col>
          <Col xs={12} md={12} xl={7}>
            {isFlipped ? (
              <div>
                <h2>{matchingCard.name}</h2>
                <div className="fortune">
                  {matchingCard.fortune_telling.map((fortune, index) => (
                    <p key={index}>{fortune}</p>
                  ))}
                </div>
                <div className="affirmation">
                  {matchingCard.Affirmation ? (
                    <p className="affirm">
                      {matchingCard.Affirmation.match(/"([^"]*)"/)[1]}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                {matchingCard.Affirmation ? (
                  <div className="keywords-affirm">
                    {matchingCard.keywords.map((keyword, index) => (
                      <span key={index} className="keyword">
                        {keyword}
                      </span>
                    ))}
                  </div>
                ) : (
                  <div className="keywords">
                    {matchingCard.keywords.map((keyword, index) => (
                      <span key={index} className="keyword">
                        {keyword}
                      </span>
                    ))}
                  </div>
                )}
              </div>
            ) : (
              <h2>{""}</h2>
            )}
          </Col>
        </Row>
      </Container>
      <div className="backBtn">
        <RevealButton s1="back" onClick={() => navigate(`${from}`)} />
      </div>
      <GoToTop />
    </div>
  );
};

export default SelectedCard;
