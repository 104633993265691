import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import {
  useGetProfileQuery,
  useProfileMutation,
} from "../slices/usersApiSlice";

const validationSchema = yup.object({
  username: yup.string().required("닉네임을 입력해주세요"),
});
const ChangeUsernameForm = ({ onClose }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const { data: user, refetch } = useGetProfileQuery(userInfo._id);
  const [updateProfile] = useProfileMutation();

  const {
    register: formRegister,
    handleSubmit,
    formState: { errors, isSubmitting },
    trigger,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async ({ username }) => {
    try {
      await updateProfile({ username }).unwrap();
      toast.success("성공적으로 수정되었습니다");
      onClose();
      refetch();
    } catch (err) {
      toast.error("해당 닉네임이 이미 존재합니다");
    }
  };

  return (
    <Form className="new-password-container" onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3" controlId="newUsername">
        <Form.Label>닉네임 변경</Form.Label>
        <Form.Control
          {...formRegister("username")}
          type="username"
          placeholder="변경하실 닉네임을 적어주세요"
          onChange={(e) => {
            formRegister("username").onChange(e);
            trigger("username");
          }}
          autoFocus
        />
        {errors.username && (
          <p className="error mt-1">{errors.username.message}</p>
        )}
      </Form.Group>

      <button
        type="submit"
        disabled={isSubmitting}
        className="new-password-btn"
      >
        변경하기
      </button>
    </Form>
  );
};

export default ChangeUsernameForm;
