import React from "react";
import ReactQuill from "react-quill";
import "./ReadOnlyEditor.css";

const ReadOnlyEditor = ({ delta }) => {
  return (
    <div className="read-only-quill">
      <ReactQuill value={delta} readOnly={true} theme={"bubble"} />
    </div>
  );
};

export default ReadOnlyEditor;
