import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Forum from "../components/Forum";
import GoToTop from "../components/GoToTop";
import Header from "../components/Header";

const TarotForum = () => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);
  const isUserAuthenticated = !!userInfo;

  return (
    <div className="mx-auto px-5">
      <Header title="타로 포럼" text={`타로에 대해 이야기하면서 함께 배워요`} />
      <Forum />
      {isUserAuthenticated ? (
        <div className="new-post-btn-container me-5">
          <button
            className="new-post-btn"
            onClick={() => navigate("/tarot-forum-new")}
          >
            작성하기
          </button>
        </div>
      ) : (
        <></>
      )}
      <GoToTop />
    </div>
  );
};

export default TarotForum;
