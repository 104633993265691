import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { AiOutlineLogin } from "react-icons/ai";
import { BsFillShieldLockFill } from "react-icons/bs";
import { FaUserShield } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import "../components/LoginForm.css";
import star from "../imgs/star.mp4";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import GoToTop from "../components/GoToTop";
import { setCredentials } from "../slices/authSlice";
import {
  useGoogleLoginMutation,
  useLoginMutation,
  useNaverLoginMutation
} from "../slices/usersApiSlice";

const LoginForm = (props) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isMdScreen, setIsMdScreen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 550);
      setIsMdScreen(window.innerWidth < 1024);
    };

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const schema = yup
    .object({
      email: yup
        .string()
        .email("이메일 주소를 입력해주세요")
        .required("이메일을 입력해주세요"),
      // password: yup.string().min(8, '비밀번호는 최소 8자리를 입력해주세요').required('비밀번호를 입력해주세요'),
    })
    .required();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      const res = await login(data).unwrap();
      dispatch(setCredentials({ ...res }));
      navigate(redirect);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [login, { isLoading }] = useLoginMutation();

  const { userInfo } = useSelector((state) => state.auth);

  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const redirect = sp.get("redirect") || "/";

  const [naverLoginCode] = useNaverLoginMutation();
  const [googleLoginCode] = useGoogleLoginMutation();

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [navigate, redirect, userInfo]);

  useEffect(() => {
    const naverLogin = new window.naver.LoginWithNaverId({
      clientId: process.env.REACT_APP_NAVER_CLIENT_ID,
      callbackUrl: "https://thetarotgate.com/register",
      isPopup: false,
      loginButton: { color: "green", type: 1, height: 27 }, // Customize login button
    });

    naverLogin.init();
    const hash = window.location.hash;
    // Parse the URL and extract the code after the component mounts
    const params = new URLSearchParams(hash.substr(1));
    const accessToken = params.get("access_token");

    const handleNaverLogin = async (accessToken) => {
      try {
        const res = await naverLoginCode(accessToken).unwrap();
        dispatch(setCredentials({ ...res }));
        navigate(redirect);
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    };
    if (accessToken) {
      handleNaverLogin(accessToken);
    }
  }, []);

  useEffect(() => {
    window.google?.accounts.id.initialize({
      client_id: `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`,
      callback: handleCredentialResponse,
    });
  }, []);

  const handleCredentialResponse = async (response) => {
    const accessToken = response.credential;
    // Send this response.credential (JWT ID token) to your backend for verification and user handling
    try {
      const res = await googleLoginCode(accessToken).unwrap();
      dispatch(setCredentials({ ...res }));
      navigate(redirect);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };
  const handleGoogleLogin = () => {
    window.google?.accounts.id.prompt();
  };

  return (
    <div className="loginPage my-flex">
      {isLoading && <Loader />}
      <div className="loginContainer my-flex">
        <div className="videoDiv">
          <video src={star}  autoPlay={isSmallScreen ? false : true} muted loop></video>
          <div className="loginTextDiv">
            <h2 className="loginTitle">운명의 카드를 탐색하다</h2>
            <p className="loginText">
              오늘 당신의 타로 카드는 무엇을 말해줄까요?
            </p>
          </div>
          <div className="loginFooterDiv my-flex">
            <span className="text">아직 계정이 없으신가요?</span>
            <Link to={"/register"}>
              <button onClick={props.toggleForm} className="loginBtn">
                회원가입
              </button>
            </Link>
          </div>
        </div>

        <div className="loginFormDiv my-flex">
          <div className="loginHeaderDiv">
            <h3>다시 오신 것을 환영합니다!</h3>
          </div>

          <form
            action=""
            className="loginForm my-grid"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="loginInputDiv">
              <label htmlFor="email">이메일</label>
              <div className="loginInput my-flex justify-content-center">
                <FaUserShield className="loginIcon" />
                <input
                  type="email"
                  id="email"
                  autoComplete="email"
                  placeholder="이메일을 입력해주세요"
                  {...register("email")}
                />
              </div>
              {errors.email && <p className="error">{errors.email.message}</p>}
            </div>
            <div className="loginInputDiv">
              <label htmlFor="password">비밀번호</label>
              <div className="loginInput my-flex justify-content-center">
                <BsFillShieldLockFill className="loginIcon" />
                <input
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  placeholder="비밀번호를 입력해주세요"
                  {...register("password")}
                />
              </div>
              {errors.password && (
                <p className="error">{errors.password.message}</p>
              )}
            </div>
            <button
              type="submit"
              className="loginBtn my-flex"
              disabled={isLoading}
            >
              <span>로그인</span>
              <AiOutlineLogin className="loginIcon" />
            </button>

            <div className="socialLogin" style={{ marginTop: "1rem" }}>
              <span>또는 다음으로 진행:</span>
              <div className="socialLoginButton">
                <motion.button
                  whileHover={{ scale: 1.3 }}
                  onClick={handleGoogleLogin}
                  type="button"
                >
                  <FcGoogle className="myicon mx-2" />
                </motion.button>
                <motion.button whileHover={{ scale: 1.3 }} type="button">
                  <div id="naverIdLogin"></div>
                </motion.button>
              </div>
            </div>

            <span className="forgotPassword">
              비밀번호를 잊어버리셨나요?
              <Link to={"/find-password"}> 비밀번호 찾기</Link>
            </span>
            <span
              onClick={props.toggleForm}
              className="forgotPassword showInSmall"
            >
              아직 계정이 없으신가요?
              <Link
                to={redirect ? `/register?redirect=${redirect}` : "/register"}
              >
                {" "}
                회원가입
              </Link>
            </span>
          </form>
        </div>
      </div>
      <GoToTop />
    </div>
  );
};

export default LoginForm;
// export default transition(LoginForm);
