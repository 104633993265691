import { AnimatePresence, motion } from "framer-motion";
import "./LikeButton.css";

const LikeButton = ({ isLiked, handleLike, isSmall, disabled }) => {
  return (
    <span
      className={
        isSmall
          ? `like-btn-container-sm ${isLiked ? "liked" : ""}`
          : `like-btn-container ${isLiked ? "liked" : ""}`
      }
    >
      <AnimatePresence>
        <motion.button
          whileTap={{ scale: 0.8 }}
          onClick={handleLike}
          type="button"
          className="like-btn"
          disabled={disabled}
        >
          {isLiked && (
            <svg
              className="svg-icon-answer-like-circle"
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="inherit"
              xmlns="http://www.w3.org/2000/svg"
            >
              <motion.circle
                key="answer-like-circle"
                initial={{
                  scale: 0,
                  opacity: 1,
                }}
                animate={{
                  scale: isLiked ? [0, 2] : 0,
                  opacity: isLiked ? [1, 0] : 0,
                }}
                transition={{
                  duration: 0.6,
                  delay: 0.2,
                }}
                opacity="1"
                cx="13"
                cy="13"
                r="13"
                fill="inherit"
              />
            </svg>
          )}
          <motion.svg
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="inherit"
            xmlns="http://www.w3.org/2000/svg"
          >
            <motion.path
              initial={{
                scale: 0,
                opacity: 0,
              }}
              animate={{
                scale: isLiked ? [1, 0, 1] : 1,
                opacity: isLiked ? 1 : 1,
              }}
              transition={{
                duration: 0.3,
              }}
              d="M 6.4279 8.402 C 7.2533 7.5968 7.688 7.243 9.037 7.273 C 10.386 7.302 11.442 7.8 12.817 11.556 C 14.374 7.83 15.694 7.566 17.014 7.331 C 18.363 7.243 19.067 7.683 19.917 8.592 C 20.767 9.619 20.826 10.586 20.767 12.023 C 20.5028 13.1411 19.9897 14.28 19.115 15.1272 L 13.3234 20.7757 C 13.2511 20.8468 13.1651 20.9032 13.0704 20.9417 C 12.9757 20.9802 12.874 21 12.7714 21 C 12.6688 21 12.5672 20.9802 12.4725 20.9417 C 12.3777 20.9032 12.2917 20.8468 12.2195 20.7757 L 6.4279 15.1272 C 5.9749 14.6857 5.6156 14.1615 5.3705 13.5845 C 5.1253 13.0075 4.9991 12.3891 4.9991 11.7646 C 4.9991 11.14 5.1253 10.5216 5.3705 9.9446 C 5.6156 9.3676 5.9749 8.8434 6.4279 8.402 Z"
              fill="inherit"
            />
            <motion.path
              initial={{
                scale: 0,
                opacity: 0,
                x: 6,
                y: 0,
              }}
              animate={{
                scale: [1, 3],
                x: isLiked ? [6, -6] : 6,
                y: 0,
                opacity: isLiked ? [0, 1, 0] : 0,
              }}
              transition={{
                duration: 0.6,
              }}
              d="M 3 13.5 C 2.923 13.794 2.794 13.888 2.5 14 C 2.171 13.888 2.062 13.785 2 13.5 C 2 13.2239 2.2239 13 2.493 13.493 C 2.7761 13 3 13.2239 3 13.5 Z"
              fill="inherit"
            />
            <motion.path
              initial={{
                scale: 0,
                opacity: 0,
                x: 0,
                y: -6,
              }}
              animate={{
                scale: [1, 3],
                y: isLiked ? [-6, 6] : -6,
                x: 0,
                opacity: isLiked ? [0, 1, 0] : 0,
              }}
              transition={{
                duration: 0.6,
              }}
              d="M 13 23.5 C 12.926 23.788 12.838 23.873 12.5 24 C 12.147 23.87 12.059 23.768 12 23.5 C 12 23.2239 12.2239 23 12.51 23.504 C 12.7761 23 13 23.2239 13 23.5 Z"
              fill="inherit"
            />
            <motion.path
              initial={{
                scale: 0,
                opacity: 0,
                x: 0,
                y: 6,
              }}
              animate={{
                scale: [1, 3],
                y: isLiked ? [6, -6] : 6,
                x: 0,
                opacity: isLiked ? [0, 1, 0] : 0,
              }}
              transition={{
                duration: 0.6,
              }}
              d="M 13 4.5 C 12.926 4.841 12.817 4.876 12.5 5 C 12.15 4.885 12.062 4.736 12 4.5 C 12 4.2239 12.2239 4 12.507 4.455 C 12.7761 4 13 4.2239 13 4.5 Z"
              fill="inherit"
            />
            <motion.path
              initial={{
                scale: 0,
                opacity: 0,
                y: 0,
                x: -6,
              }}
              animate={{
                scale: [1, 3],
                x: isLiked ? [-6, 5] : 5,
                y: 0,
                opacity: isLiked ? [0, 1, 0] : 0,
              }}
              transition={{
                duration: 0.6,
              }}
              d="M 25 13.5 C 24.943 13.794 24.774 13.911 24.5 14 C 24.168 13.888 24.071 13.768 24 13.5 C 24 13.2239 24.2239 13 24.498 13.484 C 24.7761 13 25 13.2239 25 13.5 Z"
              fill="inherit"
            />
            <motion.path
              initial={{
                scale: 0,
                opacity: 0,
                y: 0,
                x: 0,
              }}
              animate={{
                scale: [1, 3],
                x: isLiked ? [0, 3] : 0,
                y: isLiked ? [0, -5] : 0,
                opacity: isLiked ? [0, 1, 0] : 0,
              }}
              transition={{
                duration: 0.6,
                delay: 0.3,
              }}
              d="M 22 7.5 C 21.949 7.803 21.794 7.929 21.5 8 C 21.165 7.917 21.036 7.765 21 7.5 C 21 7.2239 21.2239 7 21.51 7.522 C 21.7761 7 22 7.2239 22 7.5 Z"
              fill="inherit"
            />
            <motion.path
              initial={{
                scale: 0,
                opacity: 0,
                y: 0,
                x: 0,
              }}
              animate={{
                scale: [1, 3],
                x: isLiked ? [0, -3] : 0,
                y: isLiked ? [0, -5] : 0,
                opacity: isLiked ? [0, 1, 0] : 0,
              }}
              transition={{
                duration: 0.6,
                delay: 0.3,
              }}
              d="M 6 6.5 C 5.937 6.812 5.785 6.894 5.5 7 C 5.168 6.896 5.042 6.771 5 6.5 C 5 6.2239 5.2239 6 5.498 6.51 C 5.7761 6 6 6.2239 6 6.5 Z"
              fill="inherit"
            />
            <motion.path
              initial={{
                scale: 0,
                opacity: 0,
                y: 0,
                x: 0,
              }}
              animate={{
                scale: [1, 3],
                x: isLiked ? [0, -3] : 0,
                y: isLiked ? [0, 5] : 0,
                opacity: isLiked ? [0, 1, 0] : 0,
              }}
              transition={{
                duration: 0.6,
                delay: 0.3,
              }}
              d="M 7 20.5 C 6.926 20.794 6.785 20.888 6.5 21 C 6.174 20.879 6.074 20.803 6 20.5 C 6 20.2239 6.2239 20 6.501 20.475 C 6.7761 20 7 20.2239 7 20.5 Z"
              fill="inherit"
            />
            <motion.path
              initial={{
                scale: 0,
                opacity: 0,
                y: 0,
                x: 0,
              }}
              animate={{
                scale: [1, 3],
                x: isLiked ? [0, 3] : 0,
                y: isLiked ? [0, 5] : 0,
                opacity: isLiked ? [0, 1, 0] : 0,
              }}
              transition={{
                duration: 0.6,
                delay: 0.3,
              }}
              d="M 21 20.5 C 20.937 20.812 20.803 20.844 20.5 21 C 20.188 20.838 20.051 20.797 20 20.5 C 20 20.2239 20.2239 20 20.507 20.399 C 20.7761 20 21 20.2239 21 20.5 Z"
              fill="inherit"
            />
          </motion.svg>
        </motion.button>
      </AnimatePresence>
    </span>
  );
};

export default LikeButton;
