import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import GoToTop from "../components/GoToTop";
import Header from "../components/Header";
import UnderlinedMenu from "../components/UnderlinedMenu";

const TarotMaster = () => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);
  const isUserAuthenticated = !!userInfo; // need to check if user is admin as well
  const isAdmin = !!userInfo?.isAdmin;

  return (
    <div className="mx-auto tarot-master-container">
      <Header
        title="타로 이야기"
        text={`타로 마스터 R과 함께하는 타로 이야기`}
      />
      <UnderlinedMenu />
      {isUserAuthenticated && isAdmin ? (
        <div className="new-post-btn-container me-5">
          <button
            className="new-post-btn"
            onClick={() => navigate("/tarot-master-new")}
          >
            작성하기
          </button>
        </div>
      ) : (
        <></>
      )}
      <GoToTop />
    </div>
  );
};

export default TarotMaster;
