import React, { useEffect, useState } from "react";
import { Form, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ProfileImg from "../imgs/default1.jpg";
import { logout } from "../slices/authSlice";
import { useDeleteImageMutation } from "../slices/imageApiSlice";
import {
  useDeleteUserMutation,
  useGetProfileQuery,
  useLogoutMutation,
  useProfileMutation,
  useUploadUserImageMutation,
} from "../slices/usersApiSlice";
import Loader from "./Loader";
import Message from "./Message";
import "./UserProfile.css";

const UserProfile = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const {
    data: user,
    isLoading,
    error,
    refetch,
  } = useGetProfileQuery(userInfo._id);
  const [isEditing, setIsEditing] = useState(false);
  const [bio, setBio] = useState(user?.bio || "");
  const [image, setImage] = useState(user?.image || "");
  const [updateProfile, { isLoading: loadingUpdate }] = useProfileMutation();
  const [uploadUserImage, { isLoading: loadingUpload }] =
    useUploadUserImageMutation();
  const [deleteImage] = useDeleteImageMutation();
  const [deleteUser] = useDeleteUserMutation();

  useEffect(() => {
    if (user) {
      setBio(user.bio);
      setImage(user.image);
    }
  }, [user]);

  const submitImageHandler = async (imageUrl) => {
    try {
      const updatedProfile = {
        image: imageUrl,
      };
      await updateProfile(updatedProfile);
      toast.success("성공적으로 수정되었습니다");
      refetch();
      navigate("/profile");
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const submitBioHandler = async (e) => {
    e.preventDefault();
    try {
      await updateProfile({ bio });
      toast.success("성공적으로 수정되었습니다");
      setIsEditing(false);
      refetch();
      navigate("/profile");
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const uploadImageHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    try {
      const res = await uploadUserImage(formData).unwrap();
      if (user.image !== "none") {
        const filename = user.image.split("/").pop();
        try {
          await deleteImage(filename).unwrap();
        } catch (err) {
          toast.error(err?.data?.message || "Failed to delete image");
        }
      }
      setImage(res.image);
      submitImageHandler(res.image);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const handleProfileChange = (value) => {
    setBio(value);
  };

  const handleEditButtonClick = () => {
    setIsEditing(!isEditing);
  };
  const deleteHandler = async (id, image) => {
    if (window.confirm("정말 탈퇴하시겠습니까?")) {
      try {
        await deleteUser(id).unwrap();

        if (image !== "none") {
          const filename = image.split("/").pop();
          try {
            await deleteImage(filename).unwrap();
          } catch (err) {
            toast.error(err?.data?.message || "Failed to delete image");
          }
        }

        // Logout and navigation logic outside the image deletion condition
        await logoutApiCall().unwrap();
        dispatch(logout());
        navigate("/");
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [logoutApiCall] = useLogoutMutation();

  const handleLogout = async () => {
    try {
      await logoutApiCall().unwrap();
      dispatch(logout());
      navigate("/");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message />
      ) : (
        <div className="user-profile-container">
          <div className="user-image-container">
            <Image
              src={user.image === "none" ? ProfileImg : user.image}
              alt="User Photo"
              roundedCircle
              className="user-image"
            />
            <Form>
              <Form.Group
                controlId="formImage"
                className="mt-1 profile-upload-button selected-button"
              >
                <Form.Label>사진 변경</Form.Label>
                <Form.Control
                  type="file"
                  onChange={(e) => {
                    uploadImageHandler(e);
                  }}
                  name="formImage"
                  style={{ display: "none" }}
                />
              </Form.Group>
            </Form>
          </div>
          {isEditing ? (
            <Form.Group controlId="userProfile" className="mb-3">
              <Form.Label className="mb-1 mt-3 profile-label">
                자기소개
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={bio}
                onChange={(e) => handleProfileChange(e.target.value)}
                className="mb-2 profile-input-box"
              />
              <button
                className="selected-button mr-2"
                onClick={submitBioHandler}
              >
                저장
              </button>
              <button
                className="selected-button"
                onClick={handleEditButtonClick}
              >
                취소
              </button>
            </Form.Group>
          ) : (
            <div>
              {user.bio ? (
                <div className="my-3">
                  <p className="user-profile-box px-2 mt-4 mb-1">{bio}</p>
                  <button
                    className="selected-button"
                    onClick={handleEditButtonClick}
                  >
                    변경
                  </button>
                </div>
              ) : (
                <div style={{ textAlign: "center" }}>
                  <button
                    className="selected-button my-3"
                    onClick={handleEditButtonClick}
                  >
                    자기소개 작성
                  </button>
                </div>
              )}
            </div>
          )}
          <button onClick={handleLogout} className="loginBtn my-3">
            로그아웃
          </button>
          <div className="forgotPassword">
            <button
              className="remove-user"
              onClick={() => deleteHandler(user._id, user.image)}
            >
              탈퇴하기
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default UserProfile;
