import React, { useCallback, useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useGetForumPostFullQuery,
  useUpdateForumPostMutation,
} from "../slices/forumPostsApiSlice";
import { useDeleteImageMutation } from "../slices/imageApiSlice";
import EditorQuill from "./EditorQuill"; // Import your EditorQuill component
import GoToTop from "./GoToTop";
import Header from "./Header";
import UploadButton from "./UploadButton";

const EditPost = () => {
  const { id: forumPostId } = useParams();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [deleteImage] = useDeleteImageMutation();

  const { data: forumPost, refetch } = useGetForumPostFullQuery(forumPostId);
  const [updateForumPost] = useUpdateForumPostMutation();
  const navigate = useNavigate();
  const [imageUrls, setImageUrls] = useState(new Set()); // State to store image URLs from EditorQuill

  const handleImageUrlsChange = useCallback((newImageUrls) => {
    setImageUrls(newImageUrls); // Update the state with the new set of image URLs
  }, []);

  useEffect(() => {
    if (forumPost) {
      setTitle(forumPost.title);
      setContent(forumPost.content);
    }
  }, [forumPost]);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleEditorChange = useCallback((newContent) => {
    setContent(newContent);
  }, []);
  const submitHandler = async (e) => {
    if (e) e.preventDefault();
    for (const imageUrl of imageUrls) {
      const filename = imageUrl.split("/").pop();
      try {
        await deleteImage(filename).unwrap();
      } catch (err) {
        toast.error(err?.data?.message || "Failed to delete image");
      }
    }
    try {
      await updateForumPost({
        forumPostId,
        title,
        content,
      });
      toast.success("해당 글이 성공적으로 수정되었습니다");
      refetch();
      navigate("/tarot-forum");
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };
  return (
    <div className="mx-auto px-5">
      <Header title="해당 글 수정" text={`어떻게 수정하실건가요?`} />
      <Container>
        <Form className="new-blog-container">
          <Form.Group controlId="formTitle" className="py-2">
            <Form.Label>제목</Form.Label>
            <Form.Control
              type="text"
              placeholder="제목을 입력해주세요"
              value={title}
              onChange={handleTitleChange}
            />
          </Form.Group>
          <EditorQuill
            placeholder="오늘은 무엇에 대해 쓰실건가요?"
            onContentChange={handleEditorChange}
            previousContent={content}
            onImageUrlsChange={handleImageUrlsChange}
          />
          <div className="d-flex justify-content-center align-items-center">
            <UploadButton onComplete={submitHandler} link="/tarot-forum" />
          </div>
        </Form>
      </Container>
      <GoToTop />
    </div>
  );
};

export default EditPost;
