import { COMMENTS_URL } from "../constants";
import { apiSlice } from "./apiSlice";

export const commentsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getComments: builder.query({
      query: ({ postId, postType }) => ({
        url: `${COMMENTS_URL}/posts/${postId}`,
        params: { postType }, // Send postType as a query parameter
      }),
      providesTags: ["Comment"],
    }),
    getCommentReplies: builder.query({
      query: (commentId) => ({
        url: `${COMMENTS_URL}/${commentId}/replies`,
      }),
      providesTags: ["Comment"],
      keepUnusedDataFor: 5,
    }),
    addComment: builder.mutation({
      query: ({ postId, newComment, postType, parent }) => ({
        url: `${COMMENTS_URL}/posts/${postId}/add`,
        method: "POST",
        body: { newComment, postType, parent },
      }),
      invalidatesTags: ["Comment"],
    }),
    updateComment: builder.mutation({
      query: ({ id, content }) => ({
        url: `${COMMENTS_URL}/${id}`,
        method: "PUT",
        body: { content },
      }),
      invalidatesTags: ["Comment"],
    }),
    deleteComment: builder.mutation({
      query: (commentId) => ({
        url: `${COMMENTS_URL}/${commentId}`,
        method: "DELETE",
      }),
    }),
    getCommentsProfile: builder.query({
      query: ({ pageNumber }) => ({
        url: `${COMMENTS_URL}/profile`,
        params: { pageNumber },
      }),
      providesTags: ["Comment"],
      keepUnusedDataFor: 5,
    }),
  }),
});

export const {
  useGetCommentsQuery,
  useGetCommentRepliesQuery,
  useAddCommentMutation,
  useUpdateCommentMutation,
  useDeleteCommentMutation,
  useGetCommentsProfileQuery,
} = commentsApiSlice;
