import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import { FaUserLarge } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import { RxHamburgerMenu } from "react-icons/rx";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logo2 from "../imgs/main1.png";
import "./NavBar.css";
import SearchBar from "./SearchBar";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const { userInfo } = useSelector((state) => state.auth);

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  });

  const handleNavCollapse = (isOpening) => {
    setIsOpen(isOpening);
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className={scrolled ? "scrolled" : ""}
      onToggle={handleNavCollapse}
    >
      <Container fluid className="navbar-container">
        <Navbar.Brand className="d-flex align-items-center">
          <Nav.Link eventKey="home" as={Link} to="/">
            <div className="d-flex align-items-center">
              <img
                src={logo2}
                width="100"
                className="d-inline-block align-top navbar-logo"
                alt="logo"
              />
              <span className="brand-text ml-2">타로게이트</span>
            </div>
          </Nav.Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle}>
          {isOpen ? (
            <span>
              <IoMdClose className="myicon" />
            </span>
          ) : (
            <span>
              <RxHamburgerMenu className="myicon" />
            </span>
          )}
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto collapsed-bg">
            <NavDropdown
              title="타로운세"
              id="tarot-nav-dropdown"
              className="navbar-dropdown navbar-dropdown-important"
            >
              <Nav.Link
                className="nav-dropdown-item"
                eventKey="today"
                as={Link}
                to="/daily"
              >
                오늘의 운세
              </Nav.Link>
              <Nav.Link
                className="nav-dropdown-item2"
                eventKey="birthday"
                as={Link}
                to="/birthday"
              >
                생일 운세
              </Nav.Link>
              <Nav.Link
                className="nav-dropdown-item3"
                eventKey="love"
                as={Link}
                to="/love"
              >
                애정운
              </Nav.Link>
              <Nav.Link
                className="nav-dropdown-item4"
                eventKey="money"
                as={Link}
                to="/money"
              >
                금전운
              </Nav.Link>
            </NavDropdown>
            <NavDropdown
              title="리딩스터디"
              id="study-nav-dropdown"
              className="navbar-dropdown navbar-dropdown-important"
            >
              <Nav.Link
                className="nav-dropdown-item"
                eventKey="tarot-master"
                as={Link}
                to="/tarot-master"
              >
                타로 마스터 R
              </Nav.Link>
              <Nav.Link
                className="nav-dropdown-item2"
                eventKey="together"
                as={Link}
                to="/tarot-forum"
              >
                타로 포럼
              </Nav.Link>
            </NavDropdown>
            {userInfo && userInfo.isAdmin ? (
              <NavDropdown
                title="관리자"
                id="admin-nav-dropdown"
                className="navbar-dropdown navbar-dropdown-important"
              >
                <Nav.Link
                  className="nav-dropdown-item"
                  eventKey="blogPosts"
                  as={Link}
                  to="/admin/blog-post-list"
                >
                  타로마스터 글
                </Nav.Link>
                <Nav.Link
                  className="nav-dropdown-item2"
                  eventKey="forumPosts"
                  as={Link}
                  to="/admin/forum-post-list"
                >
                  포럼 글
                </Nav.Link>
                <Nav.Link
                  className="nav-dropdown-item3"
                  eventKey="users"
                  as={Link}
                  to="/admin/user-list"
                >
                  유저
                </Nav.Link>
                <Nav.Link
                  className="nav-dropdown-item4"
                  eventKey="reports"
                  as={Link}
                  to="/admin/reports"
                >
                  신고
                </Nav.Link>
              </NavDropdown>
            ) : null}
          </Nav>
          {userInfo ? (
            <>
              <Nav>
                <SearchBar />
                <Nav.Link eventKey="profile" as={Link} to="/profile">
                  <FaUserLarge
                    className="profile-icon"
                    style={{ fontSize: "1.5rem" }}
                  />
                </Nav.Link>
              </Nav>
            </>
          ) : (
            <>
              <Nav>
                <SearchBar />
                <Nav.Link eventKey="login" as={Link} to="/login">
                  로그인
                </Nav.Link>
                <Nav.Link
                  className="me-3"
                  eventKey="register"
                  as={Link}
                  to="/register"
                >
                  회원가입
                </Nav.Link>
              </Nav>
            </>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
