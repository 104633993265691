import { motion, useInView } from "framer-motion";
import React, { useRef, useState, useEffect } from "react";
import { Card, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const TarotCard = ({ card, index }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const navigate = useNavigate();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 576);
    };

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Col>
      <Card
        ref={ref}
        style={{
          transform: isInView ? "none" : "translateX(-200px)",
          opacity: isInView ? 1 : 0,
          transition: `all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) ${
            // isInView ? `${index * 500}ms` : "0s"
            isInView ? (isSmallScreen ? "0ms" : `${index * 500}ms`) : "0ms"
          }`,
        }}
      >
        <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }} className="tarot-card-img-container">
          <Card.Img
            variant="top"
            src={require(`../imgs/${card["image"]}`)}
            className="tarot-card"
            onClick={() => navigate(card["link"], { state: { from: 'daily' } })}
          />
        </motion.div>
        <Card.Body>
          <Card.Title className="card-title">{card["title"]}</Card.Title>
          <Card.Text className="card-text">{card["des"]}</Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default TarotCard;
