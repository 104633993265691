import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import { FaReply } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ProfileImg from "../imgs/default1.jpg";
import {
  useAddCommentMutation,
  useDeleteCommentMutation,
  useGetCommentsQuery,
  useUpdateCommentMutation,
} from "../slices/commentsApiSlice";
import Loader from "./Loader";
import Message from "./Message";
import "./NestedComments.css";

const formatDate = (date) => {
  const postDate = new Date(date);
  const now = new Date();
  const differenceInTime = now.getTime() - postDate.getTime();
  const differenceInSeconds = Math.floor(differenceInTime / 1000);
  const differenceInMinutes = Math.floor(differenceInSeconds / 60);
  const differenceInHours = Math.floor(differenceInMinutes / 60);
  const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

  if (differenceInTime < 60000) {
    // Less than 1 minute ago
    return "방금 전";
  } else if (differenceInDays > 1) {
    // Show full date
    return postDate.toLocaleDateString("ko-KR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  } else {
    // Show relative date
    if (differenceInDays < 1) {
      if (differenceInHours < 1) {
        // Less than 1 hour
        return `${differenceInMinutes}분 전`;
      } else {
        // Less than 1 day
        return `${differenceInHours}시간 전`;
      }
    } else if (differenceInDays < 2) {
      return "하루 전";
    }
  }
};

export default function NestedComments({ postId, postType }) {
  const {
    data: comments,
    isLoading,
    error,
    refetch,
  } = useGetCommentsQuery({
    postId,
    postType,
  });

  const [addComments, { isLoading: loadingUpload }] = useAddCommentMutation();
  const [updateComment, { isLoading: loadingUpdate }] =
    useUpdateCommentMutation();
  const [deleteComment] = useDeleteCommentMutation();

  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);
  const isUserAuthenticated = !!userInfo;
  const [replyingTo, setReplyingTo] = useState(null); // ID of the comment being replied to
  const [replyContent, setReplyContent] = useState(""); // Content of the reply
  const [newCommentContent, setNewCommentContent] = useState("");
  const [replyFormVisible, setReplyFormVisible] = useState({});
  const [editing, setEditing] = useState({
    id: null,
    content: "",
  });

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showR, setShowR] = useState(false);

  const handleCloseR = () => setShowR(false);
  const handleShowR = () => setShowR(true);

  const [showDelete, setShowDelete] = useState(false);

  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);

  const [showDeleteR, setShowDeleteR] = useState(false);

  const handleCloseDeleteR = () => setShowDeleteR(false);
  const handleShowDeleteR = () => setShowDeleteR(true);

  const toggleEditMode = (id, content) => {
    setEditing({ id, content });
  };

  const handleEditChange = (e) => {
    setEditing((prevState) => ({ ...prevState, content: e.target.value }));
  };

  const submitEdit = async (commentId) => {
    if (!editing.content.trim()) {
      toast.error("댓글을 작성해주세요.");
      return;
    }
    try {
      await updateComment({ id: commentId, content: editing.content }).unwrap();
      toast.success("성공적으로 수정되었습니다");
      refetch();
      setEditing({ id: null, content: "" });
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const handleReplyClick = (commentId) => {
    // Toggle the visibility of the reply form for the specific comment
    setReplyFormVisible((prevState) => ({
      ...prevState,
      [commentId]: !prevState[commentId],
    }));

    // If the form is being toggled off, clear the reply content
    if (replyFormVisible[commentId]) {
      setReplyContent("");
    }

    setReplyingTo(commentId);
  };

  const handleReplyChange = (e) => {
    setReplyContent(e.target.value);
  };

  const submitReply = async (commentId) => {
    if (!replyContent.trim()) {
      toast.error("댓글을 작성해주세요");
      return;
    }
    try {
      // Assuming your API expects a 'parent' field for replies
      await addComments({
        postId,
        newComment: replyContent,
        parent: commentId,
        postType,
      }).unwrap();

      // Clear the reply form and reset the replyingTo state
      setReplyContent("");
      setReplyingTo(null);
      toast.success("답글을 성공적으로 작성하였습니다");

      // Refetch comments to display the new reply
      refetch();
    } catch (err) {
      toast.error(err?.data?.message || "답글을 작성하지 못하였습니다");
    }
  };

  const handleNewCommentSubmit = async (e) => {
    e.preventDefault();
    try {
      await addComments({
        postId,
        newComment: newCommentContent,
        postType,
      }).unwrap();
      setNewCommentContent(""); // Clear the input field after submission
      toast.success("댓글을 성공적으로 작성하였습니다");
      refetch();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const deleteHandler = async (commentId, commentType) => {
    try {
      await deleteComment(commentId);
      refetch();
      if (commentType === "R") {
        setShowDeleteR(false);
      } else {
        setShowDelete(false);
      }
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
    // }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message />
      ) : (
        <Container className="py-5 comment-container">
          <h4 className="comment-title">Comments</h4>
          <Form className="mb-4" onSubmit={handleNewCommentSubmit}>
            <Form.Group className="mb-3">
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="댓글을 작성해주세요"
                value={newCommentContent}
                onChange={(e) => setNewCommentContent(e.target.value)}
                disabled={!isUserAuthenticated}
              />
            </Form.Group>
            {isUserAuthenticated ? (
              <button className="comment-button">작성하기</button>
            ) : (
              <button
                className="comment-button"
                onClick={() => navigate("/login")}
              >
                로그인하기
              </button>
            )}
          </Form>

          {comments?.map((comment) =>
            comment.parent == null ? (
              <Card key={comment._id} className="mb-3">
                <Card.Body>
                  <Row className="comment-row">
                    <Col xs={2} md={1}>
                      <Image
                        src={
                          comment.user?.image === "none"
                            ? ProfileImg
                            : comment.user?.image
                        }
                        roundedCircle
                        fluid
                        className="comment-avatar"
                      />
                    </Col>
                    <Col className="comment-body">
                      <span className="comment-author">
                        {comment.user?.username}
                      </span>
                      <span className="comment-time">
                        {formatDate(comment.createdAt)}
                      </span>
                      {editing.id === comment._id ? (
                        // If in edit mode, show an input field with the content
                        <Form.Group>
                          <Form.Control
                            as="textarea"
                            value={editing.content}
                            onChange={handleEditChange}
                            className="mb-2"
                          />
                          <button
                            className="comment-button"
                            onClick={() => submitEdit(comment._id)}
                          >
                            확인
                          </button>
                          <button
                            className="comment-button"
                            onClick={() =>
                              setEditing({ id: null, content: "" })
                            } // Reset editing state to exit edit mode
                          >
                            취소
                          </button>
                        </Form.Group>
                      ) : (
                        // If not in edit mode, just show the comment content
                        <p className="comment-content">{comment.content}</p>
                      )}
                      {isUserAuthenticated ? (
                        <Button
                          variant="link"
                          size="sm"
                          onClick={() => handleReplyClick(comment._id)}
                          className="p-0"
                        >
                          <FaReply className="reply-icon" />
                          <span className="reply-link ms-2">답장하기</span>
                        </Button>
                      ) : (
                        <Button
                          variant="link"
                          size="sm"
                          onClick={() => handleReplyClick(comment._id)}
                          className="p-0"
                          disabled
                        >
                          <FaReply className="reply-icon" />
                          <span className="reply-link ms-2">답장하기</span>
                        </Button>
                      )}
                      {comment?.replies?.map((reply) => (
                        <Card key={reply._id} className="mt-3">
                          <Card.Body>
                            <Row>
                              <Col xs={2} md={1}>
                                <Image
                                  src={
                                    reply.user?.image === "none"
                                      ? ProfileImg
                                      : reply.user?.image
                                  }
                                  roundedCircle
                                  fluid
                                />
                              </Col>
                              <Col>
                                <span className="comment-author">
                                  {reply.user?.username}
                                </span>
                                <span className="comment-time">
                                  {formatDate(reply.createdAt)}
                                </span>
                                {editing.id === reply._id ? (
                                  // If in edit mode, show an input field with the content
                                  <Form.Group>
                                    <Form.Control
                                      as="textarea"
                                      value={editing.content}
                                      onChange={handleEditChange}
                                      className="mb-2"
                                    />
                                    <button
                                      className="comment-button"
                                      onClick={() => submitEdit(reply._id)}
                                    >
                                      확인
                                    </button>
                                    <button
                                      className="comment-button"
                                      onClick={() =>
                                        setEditing({ id: null, content: "" })
                                      } // Reset editing state to exit edit mode
                                    >
                                      취소
                                    </button>
                                  </Form.Group>
                                ) : (
                                  <p className="comment-content">
                                    {reply.content}
                                  </p>
                                )}
                              </Col>
                              <Col xs="auto">
                                {isUserAuthenticated ? (
                                  <DropdownButton
                                    key="authenticated"
                                    drop="down"
                                    title="..."
                                    data-bs-theme="dark"
                                    className="dotBtn"
                                  >
                                    {isUserAuthenticated &&
                                    reply.user._id === userInfo._id ? (
                                      <Dropdown.Item
                                        eventKey="1"
                                        onClick={() =>
                                          toggleEditMode(
                                            reply._id,
                                            reply.content
                                          )
                                        }
                                      >
                                        수정하기
                                      </Dropdown.Item>
                                    ) : (
                                      <Dropdown.Item eventKey="1" disabled>
                                        수정하기
                                      </Dropdown.Item>
                                    )}
                                    {isUserAuthenticated &&
                                    reply.user._id === userInfo._id ? (
                                      <Dropdown.Item
                                        eventKey="2"
                                        onClick={handleShowDeleteR}
                                      >
                                        삭제하기
                                      </Dropdown.Item>
                                    ) : (
                                      <Dropdown.Item eventKey="2" disabled>
                                        삭제하기
                                      </Dropdown.Item>
                                    )}
                                    <Dropdown.Item
                                      eventKey="2"
                                      onClick={handleShowR}
                                    >
                                      신고하기
                                    </Dropdown.Item>
                                  </DropdownButton>
                                ) : (
                                  <DropdownButton
                                    key="unauthenticated"
                                    drop="down"
                                    title="..."
                                    data-bs-theme="dark"
                                    className="dotBtn"
                                  ></DropdownButton>
                                )}
                                <Modal
                                  show={showDeleteR}
                                  onHide={handleCloseDeleteR}
                                  centered
                                >
                                  <Modal.Header closeButton>
                                    <Modal.Title>⚠️ 삭제하기</Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    해당 글을 삭제하시겠습니까?
                                  </Modal.Body>
                                  <Modal.Footer>
                                    <Button
                                      variant="secondary"
                                      onClick={handleCloseDeleteR}
                                    >
                                      아니오
                                    </Button>
                                    <Button
                                      variant="danger"
                                      onClick={() =>
                                        deleteHandler(reply._id, "R")
                                      }
                                    >
                                      네
                                    </Button>
                                  </Modal.Footer>
                                </Modal>
                                <Modal
                                  show={showR}
                                  onHide={handleCloseR}
                                  centered
                                >
                                  <Modal.Header closeButton>
                                    <Modal.Title>⚠️ 신고하기</Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    해당 글을 신고하시겠습니까?
                                  </Modal.Body>
                                  <Modal.Footer>
                                    <Button
                                      variant="secondary"
                                      onClick={handleCloseR}
                                    >
                                      아니오
                                    </Button>
                                    <Button
                                      variant="danger"
                                      onClick={handleCloseR}
                                    >
                                      네
                                    </Button>
                                  </Modal.Footer>
                                </Modal>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      ))}

                      {replyingTo === comment._id &&
                        replyFormVisible[comment._id] && (
                          <Form
                            className="mt-3"
                            onSubmit={(e) => {
                              e.preventDefault();
                              submitReply(comment._id);
                            }}
                          >
                            <Form.Group className="mb-3">
                              <Form.Control
                                as="textarea"
                                rows={2}
                                placeholder="답글을 작성해주세요"
                                value={replyContent}
                                onChange={handleReplyChange}
                                className="comment-reply-box"
                              />
                            </Form.Group>
                            <button className="comment-button">확인</button>
                            <button
                              className="comment-button"
                              onClick={() => handleReplyClick(comment._id)}
                            >
                              취소
                            </button>
                          </Form>
                        )}
                    </Col>

                    <Col xs="auto">
                      {isUserAuthenticated ? (
                        <DropdownButton
                          key="authenticated"
                          drop="down"
                          title="..."
                          data-bs-theme="dark"
                          className="dotBtn"
                        >
                          {isUserAuthenticated &&
                          comment.user._id === userInfo._id ? (
                            <Dropdown.Item
                              eventKey="1"
                              onClick={() =>
                                toggleEditMode(comment._id, comment.content)
                              }
                            >
                              수정하기
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item eventKey="1" disabled>
                              수정하기
                            </Dropdown.Item>
                          )}
                          {isUserAuthenticated &&
                          comment.user._id === userInfo._id ? (
                            <Dropdown.Item
                              eventKey="2"
                              onClick={handleShowDelete}
                            >
                              삭제하기
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item eventKey="2" disabled>
                              삭제하기
                            </Dropdown.Item>
                          )}
                          <Dropdown.Item eventKey="3" onClick={handleShow}>
                            신고하기
                          </Dropdown.Item>
                        </DropdownButton>
                      ) : (
                        <DropdownButton
                          key="unauthenticated"
                          drop="down"
                          title="..."
                          data-bs-theme="dark"
                          className="dotBtn"
                        >
                          <Dropdown.Item
                            eventKey="1"
                            onClick={() =>
                              toggleEditMode(comment._id, comment.content)
                            }
                            disabled
                          >
                            수정하기
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="2"
                            onClick={handleShowDelete}
                            disabled
                          >
                            삭제하기
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="3"
                            onClick={handleShow}
                            disabled
                          >
                            신고하기
                          </Dropdown.Item>
                        </DropdownButton>
                      )}
                      <Modal
                        show={showDelete}
                        onHide={handleCloseDelete}
                        centered
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>⚠️ 삭제하기</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>해당 글을 삭제하시겠습니까?</Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="secondary"
                            onClick={handleCloseDelete}
                          >
                            아니오
                          </Button>
                          <Button
                            variant="danger"
                            onClick={() => deleteHandler(comment._id, "C")}
                          >
                            네
                          </Button>
                        </Modal.Footer>
                      </Modal>
                      <Modal show={show} onHide={handleClose} centered>
                        <Modal.Header closeButton>
                          <Modal.Title>⚠️ 신고하기</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>해당 글을 신고하시겠습니까?</Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleClose}>
                            아니오
                          </Button>
                          <Button variant="danger" onClick={handleClose}>
                            네
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            ) : (
              <></>
            )
          )}
        </Container>
      )}
    </>
  );
}
