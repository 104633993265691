import { motion, useInView } from "framer-motion";
import React, { useRef } from "react";
import { FaArrowDown } from "react-icons/fa6";
import "../components/Contact.css";
import ContactForm from "../components/ContactForm";
import GoToTop from "../components/GoToTop";

const Contact = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  return (
    <div className="contactpage-container">
      <div className="contactpage-inner-container">
        <div className="contact-overlay-container">
          <motion.div
            className="arrow-container arrow-contact"
            initial={{ y: 5 }}
            animate={{ y: ["-5%", "5%"] }}
            transition={{
              duration: 0.8,
              repeat: Infinity,
              repeatType: "reverse",
            }}
            // className="arrow-contact"
            style={{
              position: "relative",
              zIndex: 2, // Ensure arrow is above the form
              // Rest of your styles...
            }}
          >
            <FaArrowDown />
          </motion.div>

          <div
            className="contact-form-container"
            style={{ position: "relative", zIndex: 1 }}
          >
            <ContactForm />
          </div>
        </div>

        {/* <motion.div
          initial={{ y: 5 }}
          animate={{ y: ["-5%", "5%"] }}
          transition={{
            duration: 0.8,
            repeat: Infinity,
            repeatType: "reverse",
          }}
          className="arrow-contact"
        >
          <motion.div
            transition={{ duration: 1 }}
            style={{
              fontSize: "2rem",
            }}
          >
            <FaArrowDown />
          </motion.div>
        </motion.div> */}

        {/* <ContactForm /> */}

        <h1
          ref={ref}
          style={{
            transform: isInView ? "none" : "translateX(200px)",
            opacity: isInView ? 1 : 0,
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s",
          }}
        >
          문의사항
        </h1>
      </div>
      <GoToTop />
    </div>
  );
};

export default Contact;
