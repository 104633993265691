import { FORUM_POSTS_URL } from "../constants";
import { apiSlice } from "./apiSlice";

export const forumPostsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getForumPosts: builder.query({
      query: ({ keyword, pageNumber }) => ({
        url: FORUM_POSTS_URL,
        params: { keyword, pageNumber },
      }),
      providesTags: ["ForumPost"],
      keepUnusedDataFor: 5,
    }),
    getForumPostFull: builder.query({
      query: (forumPostId) => ({
        url: `${FORUM_POSTS_URL}/${forumPostId}`,
      }),
      keepUnusedDataFor: 5,
    }),
    addForumPost: builder.mutation({
      query: (newPost) => ({
        url: FORUM_POSTS_URL,
        method: "POST",
        body: newPost,
      }),
      invalidatesTags: ["ForumPost"],
    }),
    updateForumPost: builder.mutation({
      query: (data) => ({
        url: `${FORUM_POSTS_URL}/${data.forumPostId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["ForumPost"],
    }),
    getForumPostsProfile: builder.query({
      query: ({ pageNumber }) => ({
        url: `${FORUM_POSTS_URL}/profile`,
        params: { pageNumber },
      }),
      providesTags: ["ForumPost"],
      keepUnusedDataFor: 5,
    }),
    deleteForumPost: builder.mutation({
      query: (forumPostId) => ({
        url: `${FORUM_POSTS_URL}/${forumPostId}`,
        method: "DELETE",
      }),
    }),
    likeForumPost: builder.mutation({
      query: (data) => ({
        url: `${FORUM_POSTS_URL}/${data.forumPostId}/like`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["ForumPost"],
    }),
  }),
});

export const {
  useGetForumPostsQuery,
  useGetForumPostFullQuery,
  useAddForumPostMutation,
  useUpdateForumPostMutation,
  useGetForumPostsProfileQuery,
  useDeleteForumPostMutation,
  useLikeForumPostMutation,
} = forumPostsApiSlice;
