import React from "react";
import { useParams } from "react-router-dom";
import { useGetForumPostsQuery } from "../slices/forumPostsApiSlice";
import "./Forum.css";
import Loader from "./Loader";
import Message from "./Message";
import Paginate from "./Paginate";
import PostSummary from "./PostSummary";

const Forum = () => {
  const { pageNumber, keyword } = useParams();
  const { data, isLoading, error } = useGetForumPostsQuery({
    keyword,
    pageNumber,
  });

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message />
      ) : (
        <>
          <div className="forum-list">
            {data.forumPosts.map((post) => (
              <div key={post._id}>
                <PostSummary postId={post._id} {...post} />
              </div>
            ))}
          </div>
          <Paginate
            pages={data.pages}
            page={data.page}
            curLocation="tarot-forum"
          />
        </>
      )}
    </>
  );
};

export default Forum;
