import React from "react";
import Hero from "../components/Hero";
import AboutBanner from "../components/AboutBanner";
import TarotBanner from "../components/TarotBanner";
import PremiumBanner from "../components/PremiumBanner";
import GoToTop from "../components/GoToTop";

const Home = () => {
  return (
    <div>
      <Hero
        cName="hero"
        heroMedia={require("../imgs/hero.mov")}
        title="타로를 통해 당신의 운명을 발견하세요"
        mediaType="video"
        btnCName="show"
      />
      <AboutBanner />
      <TarotBanner />
      <PremiumBanner />
      <GoToTop />
    </div>
  );
};

export default Home;
