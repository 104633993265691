import { AnimatePresence } from "framer-motion";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import AddBlog from "./components/AddBlog";
import AddPost from "./components/AddPost";
import AdminRoute from "./components/AdminRoute";
import EditBlog from "./components/EditBlog";
import EditPost from "./components/EditPost";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import PrivateRoute from "./components/PrivateRoute";
import SearchResultsScreen from "./components/SearchResultsScreen";
import About from "./pages/About";
import Birthday from "./pages/Birthday";
import BlogFull from "./pages/BlogFull";
import Contact from "./pages/Contact";
import Daily from "./pages/Daily";
import Home from "./pages/Home";
import LoginForm from "./pages/LoginForm";
import Lover from "./pages/Lover";
import Money from "./pages/Money";
import PasswordForm from "./pages/PasswordForm";
import PostFull from "./pages/PostFull";
import RegisterForm from "./pages/RegisterForm";
import SelectedCard from "./pages/SelectedCard";
import TarotForum from "./pages/TarotForum";
import TarotMaster from "./pages/TarotMaster";
import UserProfilePage from "./pages/UserProfilePage";
import BlogPostList from "./pages/admin/BlogPostList";
import ForumPostList from "./pages/admin/ForumPostList";
import ReportList from "./pages/admin/ReportList";
import UserEdit from "./pages/admin/UserEdit";
import UserList from "./pages/admin/UserList";
import { logout } from "./slices/authSlice";
import PageNotFound from "./pages/PageNotFound";

function App() {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const expirationTime = localStorage.getItem("expirationTime");
    if (expirationTime) {
      const currentTime = new Date().getTime();
      if (currentTime > expirationTime) {
        dispatch(logout());
      }
    }
  }, [dispatch]);
  return (
    <div className="App">
      <NavBar />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          
          <Route path="/" element={<Home />} />
          <Route path="/daily" element={<Daily />} />
          <Route path="/birthday" element={<Birthday />} />
          <Route path="/love" element={<Lover />} />
          <Route path="/money" element={<Money />} />
          <Route path="/selected-card" element={<SelectedCard />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/register" element={<RegisterForm />} />
          <Route path="/tarot-master" element={<TarotMaster />} />
          <Route
            path="/tarot-master/page/:pageNumber"
            element={<TarotMaster />}
          />
          <Route path="/tarot-forum" element={<TarotForum />} />
          <Route
            path="/tarot-forum/page/:pageNumber"
            element={<TarotForum />}
          />
          <Route path="/tarot-master/post/:id" element={<BlogFull />} />
          <Route path="/tarot-forum/post/:id" element={<PostFull />} />
          <Route path="/tarot-forum-new" element={<AddPost />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/find-password" element={<PasswordForm />} />
          <Route path="/search/:keyword" element={<SearchResultsScreen />} />
          <Route
            path="/search/:keyword/page/:pageNumber"
            element={<SearchResultsScreen />}
          />
          <Route path="" element={<PrivateRoute />}>
            <Route path="/profile" element={<UserProfilePage />} />
            <Route
              path="/profile/tarot-forum/page/:pageNumber"
              element={<UserProfilePage />}
            />
            <Route
              path="/profile/comment/page/:pageNumber"
              element={<UserProfilePage />}
            />
            <Route path="/forum-post/:id/edit" element={<EditPost />} />
          </Route>
          <Route path="" element={<AdminRoute />}>
            <Route path="/admin/blog-post-list" element={<BlogPostList />} />
            <Route
              path="/admin/blog-post-list/page/:pageNumber"
              element={<BlogPostList />}
            />
            <Route path="/admin/forum-post-list" element={<ForumPostList />} />
            <Route
              path="/admin/forum-post-list/page/:pageNumber"
              element={<ForumPostList />}
            />
            <Route path="/admin/user-list" element={<UserList />} />
            <Route
              path="/admin/user-list/page/:pageNumber"
              element={<UserList />}
            />
            <Route path="/admin/reports" element={<ReportList />} />
            <Route path="/admin/user/:id/edit" element={<UserEdit />} />
            <Route path="/tarot-master-new" element={<AddBlog />} />
            <Route path="/admin/blog-post/:id/edit" element={<EditBlog />} />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </AnimatePresence>
      <Footer />
    </div>
  );
}

export default App;
