import React, { useEffect, useState } from "react";
import { Col, Container, FloatingLabel, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useLocation, useNavigate } from "react-router-dom";
import "../components/Birthday.css";
import GoToTop from "../components/GoToTop";
import Header from "../components/Header";
import RevealButton from "../components/RevealButton";
function sumOfDigits(num) {
  const numStr = Math.abs(num).toString();
  let digitSum = 0;

  for (let i = 0; i < numStr.length; i++) {
    digitSum += parseInt(numStr[i], 10);
  }
  return digitSum;
}

const Birthday = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1114);
    };

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedCard, setSelectedCard] = useState(0);

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const currentDay = new Date().getDate();

  const [birthDate, setBirthDate] = useState(null);
  const [birthYear, setBirthYear] = useState(null);
  const [birthMonth, setBirthMonth] = useState(null);
  const [birthDay, setBirthDay] = useState(null);

  const handleDateChange = (date) => {
    setBirthDate(date);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    setBirthYear(year);
    setBirthMonth(month);
    setBirthDay(day);
  };

  const getMaxDaysInMonth = (year, month) => {
    return new Date(year, month, 0).getDate();
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    const birthdayTotal = birthDay + birthMonth + birthYear;
    const userNum = sumOfDigits(birthdayTotal);
    let cardToUse = userNum;

    if (userNum > 22) {
      cardToUse = sumOfDigits(userNum);
      setSelectedCard(cardToUse);
    } else if (userNum < 22) {
      setSelectedCard(cardToUse);
    } else {
      cardToUse = 0;
    }
    navigate("/selected-card", {
      state: {
        userCard: `major ${cardToUse}.jpg`,
        tarotType: "birthday",
        from: location.pathname,
      },
    });
  };

  return (
    <div style={{ paddingBottom: "150px" }}>
      <Header
        title="생일 운세"
        subtitle="생각을 비우고 편안한 마음으로 생년월일 (양력)을 선택해주세요."
        text={`
        당신의 생일 카드로 자신의 성향을 파악하고 숨겨진 의미를 발견하여 더 행복한 미래로 나아갈 수 있도록 해보세요. 
        이 타로 리딩은 당신의 특별한 날과 앞으로의 일상 생활에 대한 통찰력을 제공하고 긍정적인 에너지를 안겨줄 것입니다.
    `}
      />
      <div
        style={{ paddingBottom: "150px", animation: "fadeIn 0.5s ease-in-out" }}
      >
        <div>
          <Form>
            <Container fluid>
              <Row className="mb-3 align-items-center">
                <Col style={{ zIndex: 5 }} >
                  <DatePicker
                    showIcon
                    toggleCalendarOnIconClick
                    selected={birthDate}
                    onChange={handleDateChange}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    dateFormat="yyyy-MM-dd"
                    popperPlacement="bottom"
                    onFocus={(e) => (e.target.readOnly = true)}
                  />
                </Col>
              </Row>
              <Row className="mb-3 justify-content-center">
                <Col xs={3} md={3} xl={3} className="birthday-col">
                  <FloatingLabel controlId="birthYear" label="年">
                    <Form.Control
                      type="number"
                      value={birthYear || ""}
                      onChange={(event) => {
                        const newYear = parseInt(event.target.value, 10);
                        if (newYear > currentYear) {
                          setBirthYear(currentYear);
                        } else {
                          setBirthYear(newYear);
                        }
                      }}
                    />
                  </FloatingLabel>
                </Col>
                <Col xs={3} md={3} xl={3} className="birthday-col">
                  <FloatingLabel controlId="birthMonth" label="月">
                    <Form.Control
                      type="number"
                      value={birthMonth || ""}
                      onChange={(event) => {
                        const newMonth = parseInt(event.target.value, 10);
                        if (newMonth < 1 || newMonth > 12) {
                          setBirthMonth(currentMonth);
                        } else {
                          setBirthMonth(newMonth);
                        }
                        if (birthDay && newMonth) {
                          const maxDays = getMaxDaysInMonth(
                            birthYear,
                            newMonth
                          );
                          setBirthDay(Math.min(birthDay, maxDays));
                        }
                      }}
                    />
                  </FloatingLabel>
                </Col>
                <Col xs={3} md={3} xl={3} className="birthday-col">
                  <FloatingLabel controlId="birthDate" label="日">
                    <Form.Control
                      type="number"
                      value={birthDay || ""}
                      onChange={(event) => {
                        const newDay = parseInt(event.target.value, 10);
                        const maxDays = getMaxDaysInMonth(
                          birthYear,
                          birthMonth
                        );
                        setBirthDay(Math.min(newDay, maxDays));
                      }}
                      max={getMaxDaysInMonth(birthYear, birthMonth)}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
            </Container>
          </Form>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <RevealButton s1="open" onClick={handleSubmit} />
      </div>
      <GoToTop />
    </div>
  );
};

export default Birthday;
// export default transition(Birthday);
