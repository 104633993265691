import { useInView } from "framer-motion";
import React, { useRef } from "react";
import { Col, Row } from "react-bootstrap";
import "../components/About.css";
import GoToTop from "../components/GoToTop";
import LiquidButton from "../components/LiquidButton";
import tarotImg from "../imgs/logo.png";

const About = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  return (
    <div className="about-container">
      <h1
        ref={ref}
        style={{
          transform: isInView ? "none" : "translateX(-200px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(.5,-.75,.7,2) 0.2s",
        }}
      >
        타로게이트?
      </h1>
      <div className="about-intro-container">
        <Row className="align-items-center">
          <Col xs={12} md={3} xl={2}>
            <img
              ref={ref}
              style={{
                transform: isInView ? "none" : "none",
                opacity: isInView ? 1 : 0,
                transition:
                  "all 0.9s cubic-bezier(0.645, 0.045, 0.355, 1) 0.3s",
              }}
              src={tarotImg}
              alt="tarot"
            />
          </Col>
          <Col xs={12} md={9} xl={10}>
            <div className="about-intro">
              <p
                ref={ref}
                style={{
                  transform: isInView ? "none" : "none",
                  opacity: isInView ? 1 : 0,
                  transition:
                    "all 0.9s cubic-bezier(0.645, 0.045, 0.355, 1) 0.3s",
                }}
              >
                타로게이트는 타로를 통해 자신의 감정, 생각, 그리고 삶의 경로에
                더 깊이 이해할 수 있도록 돕고자 합니다.
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 4, offset: 7 }} xl={{ span: 3, offset: 8 }}>
            <div className="mini-intro">
              <p
                ref={ref}
                style={{
                  transform: isInView ? "none" : "none",
                  opacity: isInView ? 1 : 0,
                  transition:
                    "all 0.9s cubic-bezier(0.645, 0.045, 0.355, 1) 0.3s",
                }}
              >
                {" "}
                타로에 대한 열정을 공유하고, 타로를 통한 자기 발견의 여정을 다른
                이들과 함께 나누어보아요.
              </p>
            </div>
            <div>
              <LiquidButton text="시작하기" color="dark" link="/login" />
            </div>
          </Col>
        </Row>
      </div>
      <GoToTop />
    </div>
  );
};

export default About;
