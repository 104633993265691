import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { FaArrowLeft, FaRegCommentDots } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import GoToTop from "../components/GoToTop";
import LikeButton from "../components/LikeButton";
import Loader from "../components/Loader";
import Message from "../components/Message";
import NestedComments from "../components/NestedComments";
import "../components/PostFull.css";
import ReadOnlyEditor from "../components/ReadOnlyEditor";
import { likeForumPostLocal } from "../slices/authSlice";
import {
  useGetForumPostFullQuery,
  useLikeForumPostMutation,
} from "../slices/forumPostsApiSlice";

const formatDate = (date) => {
  const postDate = new Date(date);

  return postDate.toLocaleDateString("ko-KR", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

const PostFull = () => {
  const navigate = useNavigate();
  const { id: forumPostId } = useParams();
  const [mockLike, setMockLike] = useState(false);
  const { userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const {
    data: forumPost,
    isLoading,
    error,
    refetch,
  } = useGetForumPostFullQuery(forumPostId);
  const [likeForumPost] = useLikeForumPostMutation();

  const isUserAuthenticated = userInfo ? true : false;

  useEffect(() => {
    if (isUserAuthenticated && userInfo?.likedForumPosts && forumPostId) {
      // Check if the current postId is in the user's likedPosts array
      const hasLiked = userInfo.likedForumPosts.includes(forumPostId);
      setMockLike(hasLiked);
    } else if (!isUserAuthenticated) {
      setMockLike(true);
    } else {
      // Set mockLike to false for unauthenticated users or if likedPosts is not available
      setMockLike(false);
    }
  }, [isUserAuthenticated, userInfo?.likedForumPosts, forumPostId]);

  const submitLike = async () => {
    try {
      // Assuming likeForumPost is an async function that sends a request to your backend
      await likeForumPost({ forumPostId, likeStatus: !mockLike }).unwrap();

      // Optimistically update the UI to reflect the new like status
      setMockLike(!mockLike);

      // Optionally, you can refetch the post data to get the updated like count
      refetch();
      dispatch(likeForumPostLocal({ postId: forumPostId }));
    } catch (err) {
      toast.error(err?.data?.message || "Failed to update like status");
      // Revert the like status in case of an error
      setMockLike(mockLike);
    }
  };

  const handleLikeClick = () => {
    if (isUserAuthenticated) {
      submitLike();
    } else {
      toast.info("로그인을 해주세요");
    }
  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message />
      ) : (
        <article className="forum-post-full-container">
          <Container>
            <Row className="forum-post-full-header">
              <Row>
                <Col className="forum-post-back-container">
                  <Button onClick={() => navigate(-1)} className="goBackBtn">
                    <FaArrowLeft className="me-2" /> 돌아가기
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h1 className="forum-post-full-title">{forumPost.title}</h1>
                </Col>
              </Row>
              <Row>
                <Col className="forum-reactions-sm">
                  <span className="forum-post-full-author me-3">
                    {forumPost.username}
                  </span>
                  <span className="forum-post-full-date me-3">
                    {formatDate(forumPost.createdAt)}
                  </span>
                  <LikeButton
                    isLiked={mockLike}
                    handleLike={handleLikeClick}
                    isSmall={true}
                    disabled={!isUserAuthenticated || mockLike}
                  />
                  <span className="forum-post-full-icon me-3">
                    {" "}
                    {forumPost.numLikes}
                  </span>
                  <span className="forum-reactions-sm">
                    <FaRegCommentDots className="forum-post-full-comment-icon me-1" />
                  </span>
                  <span className="forum-post-full-icon">
                    {forumPost.numComments}
                  </span>
                </Col>
              </Row>
            </Row>

            <Row>
              <Col className="blog-content-container">
                <ReadOnlyEditor delta={forumPost.content} />
              </Col>
            </Row>
            <NestedComments postId={forumPostId} postType="ForumPost" />
          </Container>
          <GoToTop />
        </article>
      )}
    </>
  );
};

export default PostFull;
