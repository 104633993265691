import { useInView } from "framer-motion";
import React, { useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import aboutImg from "../imgs/about-banner.png";
import "./AboutBanner.css";

const AboutBanner = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  return (
    <section id="about" className="about-banner">
      <Container>
        <Row>
          <Col xs={12} md={7} xl={7}>
            <h2>About</h2>
            <h3 className="title">타로란?</h3>
            <img src={aboutImg} alt="tarot illustration" />
          </Col>
          <Col xs={12} md={5} xl={5}>
            <p
              ref={ref}
              style={{
                transform: isInView ? "none" : "translateX(-200px)",
                opacity: isInView ? 1 : 0,
                transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s",
              }}
            >
              우리는 인생이라는 긴 여정 속에서 즐겁고 행복할때도 있지만 삶이
              힘들고 지쳐서 어디로 가야할지 방향을 잃을때도 있습니다. 그럴때
              우리 인생의 길잡이로 타로 카드를 이용하여 정해진 자신의 운명을
              예측하여 더 나은 미래를 계획하고 행복한 삶을 살도록 도움을 받을 수
              있습니다.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutBanner;
