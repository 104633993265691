import React, { useEffect, useState } from "react";
import { Col, Container, DropdownButton, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaRegCommentDots } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ProfileImg from "../imgs/default1.jpg";
import {
  useDeleteForumPostMutation,
  useGetForumPostsQuery,
} from "../slices/forumPostsApiSlice";
import { useDeleteImageMutation } from "../slices/imageApiSlice";
import { useAddReportMutation } from "../slices/reportsApiSlice";
import LikeButton from "./LikeButton";
import "./PostSummary.css";
import { extractImageUrls } from "./extractImageUrls";

const formatDate = (date) => {
  const postDate = new Date(date);
  const now = new Date();
  const differenceInTime = now.getTime() - postDate.getTime();
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);
  if (differenceInDays > 7) {
    // Show full date
    return postDate.toLocaleDateString("ko-KR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  } else {
    // Show relative date
    if (differenceInDays < 1) {
      return "오늘";
    } else if (differenceInDays < 2) {
      return "하루 전";
    } else {
      return `${Math.floor(differenceInDays)}일 전`; // Handle 2-7 days
    }
  }
};

const PostSummary = (props) => {
  const forumPostId = props.postId;
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);
  const [deleteForumPost] = useDeleteForumPostMutation();
  const handlePostClick = (post) => {
    navigate(`/tarot-forum/post/${post._id}`);
  };
  const { pageNumber } = useParams();
  const { data, isLoading, error, refetch } = useGetForumPostsQuery({
    pageNumber,
  });
  const [mockLike, setMockLike] = useState(false);
  const [copiedLink, setCopiedLink] = useState("");
  const [show, setShow] = useState(false);
  const [reportReason, setReportReason] = useState("");

  const [addReport, { isLoading: loadingReport }] = useAddReportMutation();
  const [deleteImage] = useDeleteImageMutation();
  const handleClose = () => {
    setShow(false);
    setReportReason("");
  };
  const handleShow = () => setShow(true);

  const postLink = `${window.location.origin}/post/${props._id}`;

  const isUserAuthenticated = userInfo ? true : false;

  const displayDate = formatDate(props.createdAt);

  const imageUrls = extractImageUrls(props.content.ops);

  const deleteHandler = async (id) => {
    if (window.confirm("해당 글을 삭제하시겠습니까?")) {
      try {
        await deleteForumPost(id);
        for (const imageUrl of imageUrls) {
          const filename = imageUrl.split("/").pop();
          try {
            await deleteImage(filename).unwrap();
          } catch (err) {
            toast.error(err?.data?.message || "Failed to delete image");
          }
        }
        refetch();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  useEffect(() => {
    if (isUserAuthenticated && userInfo?.likedForumPosts && forumPostId) {
      // Check if the current postId is in the user's likedPosts array
      const hasLiked = userInfo.likedForumPosts.includes(forumPostId);
      setMockLike(hasLiked);
    } else if (!isUserAuthenticated) {
      setMockLike(true);
    } else {
      // Set mockLike to false for unauthenticated users or if likedPosts is not available
      setMockLike(false);
    }
  }, [isUserAuthenticated, userInfo?.likedForumPosts, forumPostId]);

  const submitReport = async () => {
    const reportData = {
      post: forumPostId,
      reason: reportReason,
    };

    try {
      await addReport(reportData).unwrap();
      toast.success("신고가 접수되었습니다");
      setReportReason("");
      handleClose();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <div className="forum-sm">
      <Container className="forum-summary">
        <Row>
          <Col xs={12} className="forum-summary-content">
            <div key={props._id} onClick={() => handlePostClick(props)}>
              <Row className="text-start">
                <Col xs={2} md={1}>
                  <img
                    src={
                      props.user?.image === "none"
                        ? ProfileImg
                        : props.user?.image
                    }
                    alt={props.user.username}
                    className="user-photo-sm"
                  />
                </Col>
                <Col xs={10} md={11}>
                  <div className="forum-summary-text ms-4">
                    <div className="forum-author-date-sm">
                      <p className="forum-author-sm">{props.user.username}</p>
                      <p className="forum-date-sm">{displayDate}</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <h2 className="forum-title-sm my-3">{props.title}</h2>
              </Row>
            </div>
            <Row className="justify-content-between">
              <Col xs="auto" className="forum-reactions-sm">
                <LikeButton
                  isLiked={mockLike}
                  isSmall={false}
                  disabled={!isUserAuthenticated || mockLike}
                />
                <span className="forum-icon-sm me-3"> {props.numLikes}</span>
                <span>
                  <FaRegCommentDots className="forum-comment-icon-sm me-1" />
                </span>
                <span className="forum-icon-sm">{props.numComments}</span>
              </Col>
              <Col xs="auto">
                {isUserAuthenticated ? (
                  <DropdownButton
                    drop="end"
                    title="..."
                    data-bs-theme="dark"
                    className="dotBtn"
                  >
                    <CopyToClipboard
                      text={postLink}
                      onCopy={() => setCopiedLink(true)}
                    >
                      <Dropdown.Item eventKey="1">링크 복사</Dropdown.Item>
                    </CopyToClipboard>
                    <Dropdown.Item eventKey="2" onClick={handleShow}>
                      신고하기
                    </Dropdown.Item>
                    {isUserAuthenticated && props.user._id === userInfo._id ? (
                      <Dropdown.Item
                        eventKey="3"
                        onClick={() => deleteHandler(forumPostId)}
                      >
                        삭제하기
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item eventKey="3" disabled>
                        삭제하기
                      </Dropdown.Item>
                    )}
                  </DropdownButton>
                ) : (
                  <DropdownButton
                    drop="end"
                    title="..."
                    data-bs-theme="dark"
                    className="dotBtn"
                  >
                    <CopyToClipboard
                      text={postLink}
                      onCopy={() => setCopiedLink(true)}
                    >
                      <Dropdown.Item eventKey="1">링크 복사</Dropdown.Item>
                    </CopyToClipboard>
                    <Dropdown.Item eventKey="2" onClick={handleShow} disabled>
                      신고하기
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="3" disabled>
                      삭제하기
                    </Dropdown.Item>
                  </DropdownButton>
                )}
                <Modal show={show} onHide={handleClose} centered>
                  <Modal.Header closeButton>
                    <Modal.Title>⚠️ 신고하기</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label>신고하는 이유를 작성해주세요</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={reportReason}
                          onChange={(e) => setReportReason(e.target.value)}
                          style={{ resize: "none" }}
                        />
                      </Form.Group>
                    </Form>
                  </Modal.Body>

                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      취소
                    </Button>
                    <Button variant="danger" onClick={submitReport}>
                      확인
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PostSummary;
