import { motion } from "framer-motion";
import React, { useRef, useState, useEffect } from "react";
import { FaArrowDown } from "react-icons/fa6";
import Typist from "react-typist";
import "./Hero.css";
import { isSafari, isDesktop } from "react-device-detect";
const Hero = (props) => {
  const containerRef = useRef();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isMdScreen, setIsMdScreen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 550);
      setIsMdScreen(window.innerWidth < 1024);
    };

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const renderMedia = () => {
    if (props.mediaType === "video") {
      return (
        <video
          autoPlay={isSmallScreen ? false : true}
          muted
          loop
          className="hero-video"
        >
          <source src={props.heroMedia} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    } else {
      return <img alt="hero" src={props.heroMedia} className="hero-img" />;
    }
  };

  const handleScroll = () => {
    if (containerRef.current) {
      const offsetTop =
        containerRef.current.getBoundingClientRect().top +
        window.scrollY +
        (window.innerHeight - 135);
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
  };

  const formatTitle = (title) => {
    const highlightedWords = ["타로", "운명"];

    return (
      <span style={{ display: "inline-block", position: "relative" }}>
        {title.split(" ").map((word, index) => (
          <span
            key={index}
            style={{
              fontSize: highlightedWords.some((highlighted) =>
                word.includes(highlighted)
              )
                ? isSmallScreen
                  ? "2.5rem"
                  : "3.5rem"
                : isSmallScreen
                ? "2rem"
                : "3rem",
              fontFamily: highlightedWords.some((highlighted) =>
                word.includes(highlighted)
              )
                ? "HSBombaram3_Regular"
                : "Cafe24Dangdanghae",
            }}
          >
            {word}{" "}
          </span>
        ))}
      </span>
    );
  };

  const draw = {
    hidden: { pathLength: 0, opacity: 0 },
    visible: (i) => {
      const delay = 1 + i * 0.5;
      return {
        pathLength: 1,
        opacity: 1,
        transition: {
          pathLength: { delay, type: "spring", duration: 1.5, bounce: 0 },
          opacity: { delay, duration: 0.01 },
        },
      };
    },
  };
  const [isHover, setHover] = useState(false);
  const liquidFillVariants = {
    rest: { scaleY: 0, originY: 1 },
    hover: { scaleY: 1.5, originY: 1 },
  };

  return (
    <div className={props.cName} ref={containerRef}>
      {renderMedia()}
      <div className="hero-text px-3">
        <Typist className="mytypist">{formatTitle(props.title)}</Typist>

        {isSafari && isDesktop ? (
          <motion.button
            className="arrow-container "
            initial={{ y: 5 }}
            animate={{ y: ["-5%", "5%"] }}
            transition={{
              duration: 0.8,
              repeat: Infinity,
              repeatType: "reverse",
            }}
            style={{
              position: "relative",
              // fontSize: "2.5rem",
              backgroundColor: "transparent",
              border: "none",
              top: "30vh",
            }}
            onClick={handleScroll}
          >
            <FaArrowDown className="arrow-contact" />
          </motion.button>
        ) : (
          <motion.svg
            width="150"
            height="150"
            viewBox="150 150 300 300"
            initial="hidden"
            animate="visible"
            style={{
              position: "relative",
              top: "10vh",
            }}
          >
            <motion.circle
              cx="300"
              cy="300"
              r="80"
              stroke="rgba(255, 255, 255, 1)"
              strokeWidth="2px"
              fill="transparent"
              variants={draw}
              custom={1}
            />
            <foreignObject x="220" y="220" width="160" height="160">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 2.4 }}
              >
                {isMdScreen ? (
                  <motion.button
                    style={{
                      width: "160px",
                      height: "160px",
                      padding: 0,
                      color: "#fff",
                      backgroundColor: "transparent",
                      border: "transparent",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "2.5rem",
                    }}
                    onClick={handleScroll}
                  >
                    <FaArrowDown />
                  </motion.button>
                ) : (
                  <motion.button
                    style={{
                      width: "160px",
                      height: "160px",
                      padding: 0,
                      backgroundColor: "transparent",
                      border: "transparent",
                      borderRadius: "50%",
                      cursor: "pointer",
                      overflow: "hidden",
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={handleScroll}
                    onHoverStart={() => setHover(true)}
                    onHoverEnd={() => setHover(false)}
                  >
                    <motion.div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: "#fff",
                        position: "absolute",
                      }}
                      variants={liquidFillVariants}
                      initial="rest"
                      animate={isHover ? "hover" : "rest"}
                      transition={{ duration: 0.5 }}
                    />
                    <motion.div
                      initial={{ y: 5 }}
                      animate={{ y: ["-5%", "5%"] }}
                      transition={{
                        duration: 0.8,
                        repeat: Infinity,
                        repeatType: "reverse",
                      }}
                    >
                      <motion.div
                        animate={{ color: isHover ? "#000000" : "#FFFFFF" }}
                        transition={{ duration: 1 }}
                        style={{
                          fontSize: "2.5rem",
                        }}
                      >
                        <FaArrowDown />
                      </motion.div>
                    </motion.div>
                  </motion.button>
                )}
              </motion.div>
            </foreignObject>
          </motion.svg>
        )}
      </div>
    </div>
  );
};

export default Hero;
