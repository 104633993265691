import { Button, Col, Container, Nav, Row, Table } from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import GoToTop from "../../components/GoToTop";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import {
  useDeleteReportMutation,
  useGetReportsQuery,
} from "../../slices/reportsApiSlice";
import "./BlogPostList.css";

const ReportList = () => {
  const { data: reports, isLoading, error, refetch } = useGetReportsQuery();
  const [deleteReport] = useDeleteReportMutation();
  const deleteHandler = async (id) => {
    if (window.confirm("해당 글을 삭제하시겠습니까?")) {
      try {
        await deleteReport(id);
        refetch();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  return (
    <Container className="admin-bloglist-container">
      <Row className="align-items-center mb-5">
        <Col>
          <h1 className="text-start">전체 신고</h1>
        </Col>
      </Row>

      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <Table
            striped
            bordered
            hover
            responsive
            className="table-sm"
            variant="dark"
          >
            <thead>
              <tr>
                <th>ID</th>
                <th>User</th>
                <th>Post</th>
                <th>Reason</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {reports.map((report) => (
                <tr key={report._id}>
                  <td>{report._id}</td>
                  <td>{report.user.username}</td>
                  <td>{report.post.title}</td>
                  <td>{report.reason}</td>

                  <td>
                    <Nav.Link
                      as={Link}
                      to={`/tarot-forum/post/${report.post._id}`}
                    >
                      <Button variant="light" className="btn-sm mx-2">
                        <FaEdit />
                      </Button>
                    </Nav.Link>
                    <Button
                      variant="danger"
                      className="btn-sm"
                      onClick={() => deleteHandler(report._id)}
                    >
                      <FaTrash style={{ color: "white" }} />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
      <GoToTop />
    </Container>
  );
};

export default ReportList;
