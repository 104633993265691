import React from "react";
import { Container, Row } from "react-bootstrap";
import "./TarotBanner.css";
import TarotCard from "./TarotCard";

const cards = [
  {
    title: "오늘의 운세",
    image: "tarot1.png",
    des: "오늘 하루의 운세를 예측해 활기찬 하루를 시작하세요.",
    link: "/daily",
  },
  {
    title: "애정운",
    image: "tarot2.png",
    des: "더 행복한 삶을 살기 위해 좋은 인연의 시작을 알아보세요.",
    link: "/love",
  },
  {
    title: "금전운",
    image: "tarot3.png",
    des: "자신의 타고난 재물복과 앞으로의 성공과 성취에 대해 알아보세요.",
    link: "/money",
  },
  {
    title: "생일 운세",
    image: "tarot4.png",
    des: "자신의 타고난 성향을 파악하고 앞으로 다가올 미래를 계획하세요.",
    link: "/birthday",
  },
];

const TarotBanner = () => {
  return (
    <section id="tarot" className="tarot-banner">
      <Container>
        <Row className="header">
          <h2>tarot</h2>
          <h3 className="title">미래에 대한 답을 찾아보세요</h3>
        </Row>
        <Row xs={1} sm={2} md={4} className="g-2">
          {cards.map((card, idx) => (
            <TarotCard key={idx} card={card} index={idx} />
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default TarotBanner;
