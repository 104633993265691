import { Button, Col, Container, Nav, Row, Table } from "react-bootstrap";
import { FaCheck, FaEdit, FaTimes, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import GoToTop from "../../components/GoToTop";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import { useDeleteImageMutation } from "../../slices/imageApiSlice";
import {
  useDeleteUserMutation,
  useGetUsersQuery,
} from "../../slices/usersApiSlice";
import "./BlogPostList.css";

const UserList = () => {
  const { data: users, refetch, isLoading, error } = useGetUsersQuery();
  const [deleteUser] = useDeleteUserMutation();
  const [deleteImage] = useDeleteImageMutation();

  const deleteHandler = async (id, image) => {
    if (window.confirm("해당 유저를 삭제하시겠습니까?")) {
      try {
        await deleteUser(id);
        if (image !== "none") {
          const filename = image.split("/").pop();
          try {
            await deleteImage(filename).unwrap();
          } catch (err) {
            toast.error(err?.data?.message || "Failed to delete image");
          }
        }
        refetch();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  return (
    <Container className="admin-bloglist-container">
      <Row className="align-items-center mb-5">
        <Col>
          <h1 className="text-start">전체 유저</h1>
        </Col>
      </Row>

      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <Table
            striped
            bordered
            hover
            responsive
            className="table-sm"
            variant="dark"
          >
            <thead>
              <tr>
                <th>ID</th>
                <th>NAME</th>
                <th>EMAIL</th>
                <th>USERNAME</th>
                <th>BIO</th>
                <th>IMAGE</th>
                <th>ADMIN</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user._id}>
                  <td>{user._id}</td>
                  <td>{user.name}</td>
                  <td>
                    <a href={`mailto:${user.email}`}>{user.email}</a>
                  </td>
                  <td>{user.username}</td>
                  <td>{user.bio}</td>
                  <td>{user.image}</td>
                  <td>
                    {user.isAdmin ? (
                      <FaCheck style={{ color: "green" }} />
                    ) : (
                      <FaTimes style={{ color: "red" }} />
                    )}
                  </td>

                  <td>
                    <Nav.Link as={Link} to={`/admin/user/${user._id}/edit`}>
                      <Button variant="light" className="btn-sm mx-2">
                        <FaEdit />
                      </Button>
                    </Nav.Link>
                    <Button
                      variant="danger"
                      className="btn-sm"
                      onClick={() => deleteHandler(user._id, user.image)}
                    >
                      <FaTrash style={{ color: "white" }} />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
      <GoToTop />
    </Container>
  );
};

export default UserList;
