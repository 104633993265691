import { Pagination } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import "./Paginate.css";

const Paginate = ({ pages, page, curLocation, keyword = "", onPageChange }) => {
  return (
    pages > 1 && (
      <div className="pagination-outer">
        {onPageChange ? (
          <Pagination>
            <Pagination.First
              onClick={() => onPageChange(1)}
              disabled={page === 1}
            />

            <Pagination.Prev
              onClick={() => onPageChange(Math.max(page - 1, 1))}
              disabled={page === 1}
            />

            {[...Array(pages).keys()].map((x) => (
              <Pagination.Item
                key={x + 1}
                active={x + 1 === page}
                onClick={() => onPageChange(x + 1)}
              >
                {x + 1}
              </Pagination.Item>
            ))}

            <Pagination.Next
              onClick={() => onPageChange(Math.min(page + 1, pages))}
              disabled={page === pages}
            />

            <Pagination.Last
              onClick={() => onPageChange(pages)}
              disabled={page === pages}
            />
          </Pagination>
        ) : (
          <Pagination>
            <LinkContainer
              to={`/${curLocation}${keyword ? `/${keyword}` : ""}/page/1`}
            >
              <Pagination.First disabled={page === 1} />
            </LinkContainer>

            <LinkContainer
              to={`/${curLocation}${
                keyword ? `/${keyword}` : ""
              }/page/${Math.max(page - 1, 1)}`}
            >
              <Pagination.Prev disabled={page === 1} />
            </LinkContainer>

            {[...Array(pages).keys()].map((x) => (
              <LinkContainer
                key={x + 1}
                to={`/${curLocation}${keyword ? `/${keyword}` : ""}/page/${
                  x + 1
                }`}
              >
                <Pagination.Item active={x + 1 === page}>
                  {x + 1}
                </Pagination.Item>
              </LinkContainer>
            ))}

            <LinkContainer
              to={`/${curLocation}${
                keyword ? `/${keyword}` : ""
              }/page/${Math.min(page + 1, pages)}`}
            >
              <Pagination.Next disabled={page === pages} />
            </LinkContainer>

            <LinkContainer
              to={`/${curLocation}${
                keyword ? `/${keyword}` : ""
              }/page/${pages}`}
            >
              <Pagination.Last disabled={page === pages} />
            </LinkContainer>
          </Pagination>
        )}
      </div>
    )
  );
};

export default Paginate;
