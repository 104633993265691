import React, { useCallback, useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useGetBlogPostFullQuery,
  useUpdateBlogPostMutation,
  useUploadBlogPostImageMutation,
} from "../slices/blogPostsApiSlice";
import { useDeleteImageMutation } from "../slices/imageApiSlice";
import EditorQuill from "./EditorQuill"; // Import your EditorQuill component
import GoToTop from "./GoToTop";
import Header from "./Header";
import UploadButton from "./UploadButton";

const categories = ["타로 카드", "타로 리딩", "타로 역사", "그외"]; // Define your categories here

const EditBlog = () => {
  const { id: blogPostId } = useParams();
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [image, setImage] = useState("");
  const [abstract, setAbstract] = useState("");
  const [content, setContent] = useState("");
  const [deleteImage] = useDeleteImageMutation();
  const { data: blogPost, refetch } = useGetBlogPostFullQuery(blogPostId);
  const [updateBlogPost] = useUpdateBlogPostMutation();
  const navigate = useNavigate();
  const [uploadBlogPostImage] = useUploadBlogPostImageMutation();
  const [imageUrls, setImageUrls] = useState(new Set()); // State to store image URLs from EditorQuill

  const handleImageUrlsChange = useCallback((newImageUrls) => {
    setImageUrls(newImageUrls); // Update the state with the new set of image URLs
  }, []);

  useEffect(() => {
    if (blogPost) {
      setTitle(blogPost.title);
      setImage(blogPost.image);
      setAbstract(blogPost.abstract);
      setCategory(blogPost.category);
      setContent(blogPost.content);
    }
  }, [blogPost]);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const handleAbstractChange = (e) => {
    setAbstract(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value); // Update the selected category
  };

  const uploadFileHandler = async (e) => {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    try {
      const res = await uploadBlogPostImage(formData).unwrap();
      toast.success(res.message);
      setImage(res.image);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const handleEditorChange = useCallback((newContent) => {
    setContent(newContent);
  }, []);

  const submitHandler = async (e) => {
    if (e) e.preventDefault();
    for (const imageUrl of imageUrls) {
      const filename = imageUrl.split("/").pop();
      try {
        await deleteImage(filename).unwrap();
      } catch (err) {
        toast.error(err?.data?.message || "Failed to delete image");
      }
    }
    try {
      await updateBlogPost({
        blogPostId,
        title,
        abstract,
        image,
        category,
        content,
      });
      toast.success("해당 글이 성공적으로 수정되었습니다");
      refetch();
      navigate("/admin/blog-post-list");
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <div className="mx-auto px-5">
      <Header title="해당 글 수정" text={`어떻게 수정하실건가요?`} />
      <Container>
        <Form className="new-blog-container">
          <Form.Group controlId="formTitle" className="py-2">
            <Form.Label>제목</Form.Label>
            <Form.Control
              type="text"
              placeholder="제목을 입력해주세요"
              value={title}
              onChange={handleTitleChange}
            />
          </Form.Group>

          <Form.Group controlId="formCategory" className="py-2">
            <Form.Label>카테고리</Form.Label>
            <Form.Control
              as="select"
              value={category}
              onChange={handleCategoryChange}
            >
              <option value="" disabled>
                카테고리를 선택해주세요
              </option>
              {categories.map((cat, index) => (
                <option key={index} value={cat}>
                  {cat}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formImage" className="py-2">
            <Form.Label>이미지</Form.Label>
            <Form.Control
              type="file"
              onChange={uploadFileHandler}
              name="formImage"
            />
          </Form.Group>
          <Form.Group controlId="formTitle" className="py-2">
            <Form.Label>요약</Form.Label>
            <Form.Control
              type="text"
              placeholder="글에 대한 요약을 적어주세요 (2-3줄)"
              value={abstract}
              onChange={handleAbstractChange}
            />
          </Form.Group>

          <EditorQuill
            placeholder="오늘은 무엇에 대해 쓰실건가요?"
            onContentChange={handleEditorChange}
            previousContent={content}
            onImageUrlsChange={handleImageUrlsChange}
          />
          <div className="d-flex justify-content-center align-items-center">
            <UploadButton onComplete={submitHandler} link="/tarot-master" />
          </div>
        </Form>
      </Container>
      <GoToTop />
    </div>
  );
};

export default EditBlog;
