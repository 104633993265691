import { motion, useScroll, useSpring } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FaArrowLeft, FaRegCommentDots } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "../components/BlogFull.css";
import GoToTop from "../components/GoToTop";
import LikeButton from "../components/LikeButton";
import Loader from "../components/Loader";
import Message from "../components/Message";
import NestedComments from "../components/NestedComments";
import ReadOnlyEditor from "../components/ReadOnlyEditor";
import { likeBlogPostLocal } from "../slices/authSlice";
import {
  useGetBlogPostFullQuery,
  useLikeBlogPostMutation,
} from "../slices/blogPostsApiSlice";

const formatDate = (date) => {
  const postDate = new Date(date);

  // Show full date
  return postDate.toLocaleDateString("ko-KR", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

const BlogFull = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const [mockLike, setMockLike] = useState(false);

  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: blogPostId } = useParams();
  const {
    data: blogPost,
    isLoading,
    error,
    refetch,
  } = useGetBlogPostFullQuery(blogPostId);
  const [likeBlogPost, { isLoading: loadingLike }] = useLikeBlogPostMutation();

  const isUserAuthenticated = userInfo ? true : false;

  useEffect(() => {
    if (isUserAuthenticated && userInfo?.likedBlogPosts && blogPostId) {
      // Check if the current postId is in the user's likedPosts array
      const hasLiked = userInfo.likedBlogPosts.includes(blogPostId);
      setMockLike(hasLiked);
    } else if (!isUserAuthenticated) {
      setMockLike(true);
    } else {
      // Set mockLike to false for unauthenticated users or if likedPosts is not available
      setMockLike(false);
      // dispatch(likeBlogPostLocal({ postId: blogPostId }));
    }
  }, [isUserAuthenticated, userInfo?.likedBlogPosts, blogPostId]);

  const submitLike = async () => {
    try {
      // Assuming likeForumPost is an async function that sends a request to your backend
      await likeBlogPost({ blogPostId, likeStatus: !mockLike }).unwrap();

      // Optimistically update the UI to reflect the new like status
      setMockLike(!mockLike);

      // Optionally, you can refetch the post data to get the updated like count
      refetch();
      dispatch(likeBlogPostLocal({ postId: blogPostId }));
    } catch (err) {
      toast.error(err?.data?.message || "Failed to update like status");
      // Revert the like status in case of an error
      setMockLike(mockLike);
    }
  };

  const handleLikeClick = () => {
    if (isUserAuthenticated) {
      submitLike();
    } else {
      toast.info("로그인을 해주세요");
    }
  };

  useEffect(() => {
    scaleX.set(0); // Reset progress to 0
    // You might also need to manually scroll to the top to ensure progress starts from 0
    window.scrollTo(0, 0);
  }, [blogPostId, scaleX]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message />
      ) : !blogPost ? (
        <Loader />
      ) : (
        <article className="blog-full-container">
          <Container>
            <Row className="blog-full-header">
              <Row>
                <Col>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id="view-all">View all posts</Tooltip>}
                  >
                    <Button onClick={() => navigate(-1)} className="goBackBtn">
                      <FaArrowLeft />
                    </Button>
                  </OverlayTrigger>
                  <span className="blog-full-category px-2 py-1">
                    {blogPost.category}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h1 className="blog-full-title">{blogPost.title}</h1>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="blog-full-abstract">{blogPost.abstract}</p>
                </Col>
              </Row>
              <Row>
                <Col className="forum-reactions-sm">
                  <span className="forum-post-full-author me-3">
                    {blogPost.username}
                  </span>
                  <span className="forum-post-full-date me-3">
                    {formatDate(blogPost.createdAt)}
                  </span>
                  <LikeButton
                    isLiked={mockLike}
                    handleLike={handleLikeClick}
                    isSmall={true}
                    disabled={!isUserAuthenticated || mockLike}
                  />
                  <span className="forum-post-full-icon me-3">
                    {" "}
                    {blogPost.numLikes}
                  </span>
                  <span className="forum-reactions-sm">
                    <FaRegCommentDots className="forum-post-full-comment-icon me-1" />
                  </span>
                  <span className="forum-post-full-icon">
                    {blogPost.numComments}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col className="blog-full-img-container">
                  {blogPost.image ? (
                    <img
                      src={blogPost.image}
                      alt={blogPost.title}
                      className="blog-full-img my-3"
                    />
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            </Row>
            <Row>
              <Col className="blog-content-container">
                <ReadOnlyEditor delta={blogPost.content} />
              </Col>
            </Row>
          </Container>
          <NestedComments postId={blogPostId} postType="BlogPost" />
        </article>
      )}
      <motion.div className="post-progress-bar" style={{ scaleX }} />
      <GoToTop />
    </>
  );
};

export default BlogFull;
