import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import GoToTop from "../components/GoToTop";
import Header from "../components/Header";
import QuestionIcon from "../components/QuestionIcon";
import RevealButton from "../components/RevealButton";
import Spreadable from "../components/Spreadable";
import SpreadableSM from "../components/SpreadableSM";
import { TarotDeck } from "../data/constants";
import SpreadableXS from "../components/SpreadableXS";

const Daily = () => {
  const navigate = useNavigate();
  let randomCard = TarotDeck[Math.floor(Math.random() * TarotDeck.length)];
  const [selectedCard, setSelectedCard] = useState(randomCard);
  const [isSmallestScreen, setIsSmallestScreen] = useState(false);
  const location = useLocation();
  const handleCardSelected = (title) => {
    setSelectedCard(title);
    // navigate("/selected-card", { state: { userCard: title } }); // Use title directly
  };
  
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setIsSmallScreen(screenWidth < 1024);
      setIsSmallestScreen(screenWidth < 692);
    };

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  return (
    <div style={{ paddingBottom: "100px" }}>
      <Header
        title="오늘의 운세"
        subtitle="생각을 비우고 편안한 마음으로 카드 한 장을 선택해주세요."
        text={`
        하루를 타로 카드로 시작하는 것은 모든 가능성에 대한 기대감을 높이고 예상치 못한 상황을 피하는 데 도움이 됩니다. 
        오늘의 타로를 확인하여 자신의 일상에 대한 통찰력을 얻고, 긍정적인 운세를 통해 행복한 하루를 보내세요!
    `}
      />
      {/* <QuestionIcon placement="right" /> */}
      {/* {isSmallScreen ? (
        <SpreadableSM onCardSelect={handleCardSeleted} />
      ) : (
        <Spreadable onCardSelect={handleCardSeleted} />
      )} */}
      {isSmallestScreen ? (
        <SpreadableXS onCardSelect={handleCardSelected} navigate={navigate} from={location.pathname} />
      ) : isSmallScreen ? (
        <SpreadableSM onCardSelect={handleCardSelected} navigate={navigate} from={location.pathname} />
      ) : (
        <Spreadable onCardSelect={handleCardSelected} navigate={navigate} from={location.pathname} />
      )}

      <div
        style={{
          position: "absolute",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {/* <RevealButton
          s1="open"
          onClick={() =>
            navigate("/selected-card", { state: { userCard: selectedCard } })
          }
        /> */}
      </div>
      <GoToTop />
    </div>
  );
};

export default Daily;
// export default transition(Daily);
