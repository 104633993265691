import React from "react";
import { useNavigate } from "react-router-dom";
import "./Blog.css";
import BlogPost from "./BlogPost";

const Blog = ({ posts }) => {
  const navigate = useNavigate();

  const handlePostClick = (post) => {
    navigate(`/tarot-master/post/${post._id}`);
  };
  return (
    <section className="blog-container px-5">
      {posts.map((post, index) => (
        <div key={post._id} onClick={() => handlePostClick(post)}>
          <BlogPost {...post} />
        </div>
      ))}
    </section>
  );
};

export default Blog;
